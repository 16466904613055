import { useState, useRef } from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { observer } from "mobx-react";
import { getCurrencies } from "project-structure";
import { MenuButton, PopMenu, TextField, TooltipIcon } from "@components";
import { Grid, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Settings } from "@assets";
import useStyle from "./CurrencyEditor.style";
import classnames from "classnames";

const options = getCurrencies();

export const CurrencyEditor = observer(({
  useCompanyCurrency
}) => {
  const classes = useStyle();
  const editorStore = useProjectEditorStore();
  const structure = useStructureStore();
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [visible, show] = useState(false);
  const [isUpdatingCurrencyRate, setUpdatingCurrencyRate] = useState(false);

  const anchor = useRef();

  const handlePopup = () => show(!visible);
  const clickAwayHandler = () => show(false);

  const setCurrency = (data) => async () => {
    const v = [
      structure.settings.companyCurrency,
      structure.settings.currency
    ];
    
    v[useCompanyCurrency ? 0 : 1] = data.code;
    
    if(useCompanyCurrency)
      structure.settings.setCompanyCurrency(v[0]);
    else
      structure.settings.setCurrency(v[1]);
    
    if(editorStore.profitabilityMode) {
      setUpdatingCurrencyRate(true);
      if(v[0] === v[1])
        structure.settings.setDefaultDailyExchangeRate(new Date().getTime());
      else try {
        const rates = await editorStore.getExchangeRates(...v);
        structure.settings.setDailyExchangeRate(rates);
      } catch(e) {
        structure.settings.setDefaultDailyExchangeRate();
      }
      setUpdatingCurrencyRate(false);
    }
    
    setValue("");
    show(false);
  };

  const { currency, companyCurrency } = structure.settings;

  const filtered = options
    .filter((o) => {
      o.selected = (useCompanyCurrency ? companyCurrency : currency) === o.code;

      const matcher = (v) =>
        v?.toLowerCase()?.indexOf(value.toLowerCase()) >= 0;

      return value
        ? matcher(o.name) ||
            matcher(o.code) ||
            matcher(o.symbolEnd) ||
            matcher(o.symbolStart)
        : true;
    })
    .sort((a, b) => {
      return a.selected
        ? -1
        : b.selected
        ? 1
        : a.order && b.order
        ? a.order > b.order
          ? 1
          : -1
        : a.order
        ? -1
        : b.order
        ? 1
        : a.name > b.name
        ? 1
        : -1;
    });

  return (
    <>
      <TooltipIcon
        icon={<Settings />}
        style={{ right: 0 }}
        ref={anchor}
        onClick={handlePopup}
        color="primary"
        className="noDrag absolute text-sm"
      >
        {t("views.editor.dialogs.currency.title")}
      </TooltipIcon>

      <PopMenu
        show={visible}
        anchor={anchor.current}
        placement="right-start"
        onClickAway={clickAwayHandler}
        className="pt-4 px-0 pb-0"
        style={{ width: 320 }}
      >
        <Grid container direction="column">
          <Grid
            item container
            justifyContent="space-between"
            alignItems="center"
            className="mb-3 px-6"
          >
            <h3>{t("views.editor.dialogs.currency.title")}</h3>
            <IconButton
              color="primary"
              aria-label="close modal"
              size="small"
              onClick={clickAwayHandler}
            >
              <Close />
            </IconButton>
          </Grid>
          <TextField
            id="filterCurrencies"
            size="small"
            onChange={(e) => setValue(e.target.value)}
            placeholder={t("views.editor.dialogs.currency.filter")}
            disabled={isUpdatingCurrencyRate}
            value={value}
            className="px-6"
            InputProps={{
              endAdornment: value && (
                <IconButton
                  aria-label="clear input"
                  color="primary"
                  onClick={() => setValue("")}
                >
                  <Close />
                </IconButton>
              ),
            }}
          />
          <div
            role="listbox"
            className={classnames(classes.list, "w-full overflow-auto pb-6")}
          >
            {
              !filtered.length &&
              <p className={classes.item}>
                {t("views.editor.dialogs.currency.not_found")}
              </p>
            }
            {
              filtered.map((o) => (
                <MenuButton
                  key={o.code}
                  className="px-6 py-2"
                  onClick={setCurrency(o)}
                  disabled={isUpdatingCurrencyRate}
                >
                  {/*{`${o.symbolStart || o.symbolEnd} - ${o.name}`}*/}
                  <span className="transparent-2 mr-2 w-8">
                    {o.code}</span>
                  {o.name}
                </MenuButton>
              ))
            }
          </div>
        </Grid>
      </PopMenu>
    </>
  );
});

CurrencyEditor.propTypes = {
  useCompanyCurrency: bool
}