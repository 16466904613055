import { useMemo } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { TotalContainer } from "../components";

export const TotalHours = observer(( containerProps ) => {
  const {t} = useTranslation();
  const structure = useStructureStore();
  
  const {
    settings,
    visibility,
    displayTotalHours,
    displayTotalHoursMax,
    showMaxTotalHours,
  } = structure;
  const {
    timeModifier,
    usedRoundDecimals,
    showDecimals,
  } = settings;
  const { hideTotalHours } = visibility;
  const className = useVisibilityModeCellClassName(hideTotalHours);
  
  const symbolEnd = useMemo(() => (
    timeModifier === 1 ? t("times.h") : " " + t("times.day")
  ), [timeModifier]);
  
  return (
    <TotalContainer
      commentPath="hours"
      totalName="Hours"
      min={displayTotalHours}
      max={displayTotalHoursMax}
      showMaxValue={showMaxTotalHours}
      symbolEnd={symbolEnd}
      textSmaller
      decimals={usedRoundDecimals}
      showDecimals={showDecimals}
      className={className}
      visibilityHidden={hideTotalHours}
      setVisibility={visibility.setHideTotalHours}
      {...containerProps}
    />
  );
})

TotalHours.propTypes = {
  isProposal: bool,
  divider: bool,
  showChatThread: bool,
}