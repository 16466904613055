import { bool, func } from "prop-types";
import { observer } from "mobx-react";
import {
  useStructureStore,
  usePresetFieldNames,
  useTableExpandHandler,
  useProjectEditorStore,
  useVisibilityModeCellClassName
} from "@hooks";
import { Tables, hashCode } from "project-structure";
import { ProposalSettings, VisibilityBreakdownSettings } from "@dialogs";
import { Section, SortableList, NewSectionButton, TableTitle, MinMaxSwitch, BreakdownPricesSwitch } from "@components";
import { Grid, Collapse } from "@material-ui/core";
import useStyle from "../commonStyles.style";
import { VisibilityButton } from "@components";
import classnames from "classnames";

export const TableBreakdown = observer(({
  allowEdition,
  hasSellerPrivileges,
  useClientActions,
  onSectionReorder,
  isProposal,
  presetEditionMode,
  showPdfCover,
  blockExpansions,
  ...props
}) => {

  // values & systems from dataStore
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const classes = useStyle();

  const { sections, settings, visibility } = structure;
  const { showBreakdown } = settings;
  const { hideBreakdown } = visibility;
  
  const className = useVisibilityModeCellClassName(hideBreakdown);

  const handleSectionReorder = (list) => structure.reorderSections(list);
  
  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    !blockExpansions,
    showBreakdown,
    settings.setShowBreakdown
  );
  
  const sectionProps = {
    allowEdition,
    isProposal,
    useClientActions,
    blockExpansions,
    showPdfCover,
    ...props,
  }

  return (
    <Grid item container className={classnames("vCon", showPdfCover && "pdf-mode", classes.root)} >
      <TableTitle
        disabled={visibilityMode && hideBreakdown}
        expandable={!blockExpansions}
        presetClass="breakdown"
        buttonPresetClass="preset-tableBreakdownExpandButton"
        id={hashCode(Tables.BREAKDOWN)}
        title={getFieldName("pt-breakdown")}
        onContentAction={handleOpen}
        presetEditionMode={presetEditionMode}
        showContent={showBreakdown}
        className="preset-page preset-noedit"
        action={(
          visibilityMode &&
          <>
            <VisibilityButton
              hidden={hideBreakdown}
              onChange={visibility.setHideBreakdown}
            />
            <VisibilityBreakdownSettings />
          </>
        )}
      >
        {allowEdition && (
          <Grid
            item
            container
            alignItems="center"
            className="w-max my-1"
          >
            <ProposalSettings noMinMax />
            { !settings.isSummaryTableUsed && <MinMaxSwitch /> }
            { hasSellerPrivileges && <BreakdownPricesSwitch /> }
          </Grid>
        )}
      </TableTitle>
      <Collapse
        in={showBreakdown}
        timeout="auto"
        className={classnames(showPdfCover && "pdf-mode", classes.collapse, className)}
        mountOnEnter
        unmountOnExit
        data-id={hashCode("cont")}
        data-t="breakdown"
        data-p={hashCode(Tables.BREAKDOWN)}
        { ...collapseProps }
      >
        <SortableList
          list={sections}
          group="sections"
          onListReorderStart={onSectionReorder}
          onListReorder={handleSectionReorder}
          disabled={!allowEdition}
          className={classes.tableContainerWrapper}
        >
          {sections.map((section, index) => {
            return (
              <Section
                key={section.id}
                section={section}
                index={index}
                {...sectionProps}
              />
            );
          })}
        </SortableList>
        {allowEdition && <NewSectionButton />}
      </Collapse>
    </Grid>
  );
});

TableBreakdown.propTypes = {
  hasEditorPrivileges: bool,
  hasSellerPrivileges: bool,
  onSectionReorder: func,
  isProposal: bool,
  readOnly: bool,
  useClientActions: bool,
  presetEditionMode: bool,
  blockExpansions: bool,
  showPdfCover: bool,
  showChatThread: bool,
  allowCommenting: bool,
  isSellerOrClient: bool,
  isEditorOrClient: bool,
};
