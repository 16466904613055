import { useMemo } from "react";
import { useProjectEditorStore, useStores } from "@hooks";
import { isProjectObserver, isProjectOwner, PROJECT_TYPE } from "@utils";

export const useCheckProjectAccess = (
  projectUsers = undefined,
  workTypesWithStatus = undefined
) => {
  const { userStore } = useStores();
  const editorStore = useProjectEditorStore();
  const {
    userId,
    userUuid,
    isAdmin,
    isModerator,
    isBusinessUser,
    isMiniUser
  } = userStore;
  
  const isAdminOrModeratorInLibrary = useMemo(() => (
    isAdmin || (isModerator && editorStore?.type === PROJECT_TYPE.LIBRARY)
  ), [isAdmin, isModerator])

  const isOwner = useMemo(
    () => isProjectOwner(userId, projectUsers, isAdminOrModeratorInLibrary),
    [isAdmin, userId, projectUsers]
  );

  const isObserver = useMemo(
    () => isProjectObserver(userId, projectUsers, isAdminOrModeratorInLibrary),
    [isAdmin, userId, projectUsers]
  );

  const hasSharePrivileges = useMemo(
    () => isAdmin || (isBusinessUser && isObserver),
    [isAdmin, isBusinessUser, isObserver]
  );

  const hasSellerPrivileges = useMemo(
    () => isAdminOrModeratorInLibrary || isBusinessUser,
    [isAdminOrModeratorInLibrary, isBusinessUser]
  );

  const hasEditorPrivileges = useMemo(
    () => !isMiniUser && isObserver,
    [isMiniUser, isObserver]
  );

  const userGrantedPermits = useMemo(() => {
    if (!userUuid || !workTypesWithStatus?.length) return [];
    return workTypesWithStatus.filter(
      (permit) => permit.estimatorUuid === userUuid
    );
  }, [workTypesWithStatus]);

  const hasUserGrantedPermits = useMemo(
    () => Boolean(!isOwner && userGrantedPermits?.length),
    [isOwner, userGrantedPermits]
  );
  
  return {
    userUuid,
    userId,
    isOwner,
    isObserver,
    hasSharePrivileges,
    hasSellerPrivileges,
    hasEditorPrivileges,
    userGrantedPermits,
    hasUserGrantedPermits,

    isAdmin,
    isModerator,
    isMiniUser,
    isBusinessUser,
  };
};
