import { bool, func, number, oneOf, string } from "prop-types";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import useStyle from "./VisibilityButton.style";
import classnames from "classnames";

export const VisibilityButton = ({
  hidden,
  size="small",
  onChange,
  inColumn,
  inRow,
  right,
  className,
}) => {
  
  const classes = useStyle();
  
  const handleChange = () => {
    onChange(!hidden);
  }
  
  return (
    <IconButton
      onClick={handleChange}
      size={size}
      color="primary"
      style={{right: right || 0, zIndex: 2}}
      className={classnames(
        classes.root,
        size === "tiny" && "text-sm p-0-5",
        inColumn && "absolute",
        !inColumn && !inRow && "mx-2",
        className
      )}
    >
      {
        hidden ? <VisibilityOff /> : <Visibility />
      }
    </IconButton>
  );
};

VisibilityButton.propTypes = {
  onChange: func.isRequired,
  hidden: bool,
  size: oneOf(["tiny", "small", "medium", "large"]),
  inColumn: bool,
  inRow: bool,
  right: number,
  className: string,
};