import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { PriceCell } from "@components";
import classnames from "classnames";

export const CostCell = observer(({
  element,
  allowEdition,
  displayCellContent,
  profitabilityMode,
  isLastCell,
}) => {
  const structure = useStructureStore();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryCost);
  
  const { usesTwoValues, usesAnyValue, settings } = structure;
  const { useMinMax, currencyObj, roundPrice } = settings;
  const { displayPrice, displayPriceMax, hasResources, inBreakdown } = element;
  
  const showMaxValue = usesTwoValues && (allowEdition || displayPrice !== displayPriceMax);
  
  const handleCostChange = (price, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCustomPrice({ [key]: price }, true);
  };
  
  return (
    <PriceCell
      allowNegative
      emptyInputs={!usesAnyValue}
      widest={usesTwoValues}
      displayContent={displayCellContent}
      value={displayPrice}
      valueMax={displayPriceMax}
      displayMax={showMaxValue}
      onValueChange={handleCostChange}
      editable={allowEdition && !inBreakdown && !hasResources && !profitabilityMode}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      extendPadding={isLastCell}
      className={classnames("z-2", className)}
      inputClassName="input-cost"
    />
  );
});

CostCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
  profitabilityMode: bool,
  isFirstCell: bool,
  isLastCell: bool,
};