import { createContext } from "react";
import { instanceOf, node } from "prop-types";
import { observable, action, makeObservable } from "mobx";

//-------------------------------------------------------------------------------------------
export class ArchivedProjectStore {
  constructor() {
    makeObservable(this);
  }

  @observable projects = [];
  @observable rowsPerPage = 10;
  @observable page = 0;
  @observable total = 0;

  @action setArchivedProjects(projects, total) {
    this.projects = projects;
    this.total = total;
  }
  
  @action setPage(page) {
    this.page = page;
  }
  
  @action setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }
  
  @action addNewlyArchivedProject(projectData) {
    this.total = this.total + 1;
    const totalPages = Math.ceil(this.total / this.rowsPerPage);
    if(totalPages === this.page+1)
      this.projects = [...this.projects, projectData];
  }
  
  @action removeArchivedProject(uuid) {
    this.total = this.total - 1;
    this.projects = this.projects.filter((pr) => pr.uuid !== uuid);
  }
}

export const ArchivedProjectStoreContext = createContext(null);

export const ArchivedProjectStoreProvider = ({ children, value }) => (
  <ArchivedProjectStoreContext.Provider value={value}>
    {children}
  </ArchivedProjectStoreContext.Provider>
);

ArchivedProjectStoreProvider.propTypes = {
  children: node.isRequired,
  value: instanceOf(ArchivedProjectStore).isRequired,
};
