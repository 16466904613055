import { useMemo } from "react";
import { bool, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useRiskBufferAlert, useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { ValueCell, ResetValueButton, Alert } from "@components";
import { WorkTypeOverheadTooltip } from "../components/WorkTypeOverheadTooltip";

export const HourCell = observer(({
  element,
  allowEdition,
  displayCellContent,
  isWorkType,
  profitabilityMode,
}) => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryHours);
  
  const { usesTwoValues, usesAnyValue } = structure;
  const {
    useMinMax,
    modifier,
    riskBuffer,
    showDecimals,
    usedRoundDecimals,
  } = structure.settings;
  const {
    displayHours,
    displayHoursMax,
    usedOverheads,
    hasOnlyCost,
    inBreakdown,
    hasCustomHours,
    usesSummaryPercent,
  } = element;
  
  const {
    riskAlertVisible,
    handleRiskAlertClose,
    handleRiskAlertOpen,
  } = useRiskBufferAlert();

  const displayMessage = allowEdition && isWorkType && inBreakdown;
  
  const editable = allowEdition && !inBreakdown && !profitabilityMode;
  
  const showResetButton = displayCellContent && allowEdition && !inBreakdown && hasCustomHours && usesSummaryPercent;
  
  const showMaxValue = usesTwoValues && (editable || displayHours !== displayHoursMax);
  
  const displayUsedOverheads = usedOverheads?.length > 0 && displayCellContent && allowEdition && !profitabilityMode;
  
  const messageTitle = useMemo(() => {
    if(!profitabilityMode && displayMessage)
      return t("views.editor.breakdown_hours_alert.title")
  }, [displayMessage]);
  
  const messageContent = useMemo(() => {
    if(!profitabilityMode && displayMessage)
      return t("views.editor.breakdown_hours_alert.content")
  }, [displayMessage]);
  
  const handleHoursChange = (hour, isMax) => {
    structure.historyManager.startGroup();
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCustomHours({ [key]: parseFloat((hour * modifier).toPrecision(12)) });
    
    handleRiskAlertOpen();
    structure.historyManager.stopGroup();
  };
  
  const handleHoursReset = () => {
    element.resetPercentHours();
  };
  
  return (
    <ValueCell
      widest
      emptyInputs={hasOnlyCost || !usesAnyValue}
      value={displayHours}
      valueMax={displayHoursMax}
      displayMax={showMaxValue}
      onValueChange={handleHoursChange}
      displayContent={((allowEdition && !profitabilityMode) || !hasOnlyCost) && displayCellContent}
      editable={editable}
      messageTitle={messageTitle}
      messageContent={messageContent}
      showDecimals={showDecimals}
      warning={showResetButton}
      useSeparator={profitabilityMode}
      decimals={usedRoundDecimals}
      className={className}
      textPresetClass="preset-summaryText"
      inputClassName="input-hours"
      inputMinTooltipMessage={displayUsedOverheads &&
        <WorkTypeOverheadTooltip element={element} workTypeId={element.id}/>}
      inputMaxTooltipMessage={displayUsedOverheads &&
        <WorkTypeOverheadTooltip element={element} workTypeId={element.id} isMax />}
      
    >
      {showResetButton && (
        <ResetValueButton action={handleHoursReset}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
      {allowEdition && riskAlertVisible && (
        <Alert
          isOpen={riskAlertVisible}
          title={t("views.editor.dialogs.settings.risk_buffer")}
          acceptText={t("common.close")}
          onAccept={handleRiskAlertClose}
          useLockCheckbox
        >
          {t("views.editor.risk_alert", {percent: riskBuffer})}
        </Alert>
      )}
    </ValueCell>
  );
});

HourCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
  isWorkType: bool,
  profitabilityMode: bool,
};
