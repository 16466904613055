import { useRef } from "react";
import {
  bool,
  string,
  object,
  node,
  arrayOf,
  func,
  oneOfType,
} from "prop-types";
import { ReactSortable } from "react-sortablejs";
import useStyle from "./SortableList.style";
import classnames from "classnames";

export const SortableList = ({
  list = [],
  group,
  onListAdd,
  onListRemove,
  onListReorderStart,
  onListReorder,
  onListReorderEnd,
  disabled,
  useHandle,
  className,
  displayAsGrid,
  displayAsFlexbox,
  displayAsColumnFlexbox,
  children,
  style,
  path,
  itemIdKey="id",
}) => {
  const classes = useStyle();
  
  const dragStage = useRef(0);
  const draggedElementId = useRef();

  const handleListReorder = (l) => {
    if (dragStage.current === 1) {
      // draggedElementId.current = l.find((el) => el.chosen === false)?.id;
      dragStage.current = 2;
    } else if (dragStage.current === 2) {
      onListReorder && onListReorder(l, draggedElementId.current, !l.find((el) => el?.id === draggedElementId.current));
      draggedElementId.current = undefined;
      dragStage.current = 0;
    }
  };

  const handleListReorderStart = (e) => {
    draggedElementId.current = list[e.oldIndex][itemIdKey]
    dragStage.current = 1;
    onListReorderStart && onListReorderStart();
  };
  
  const handleListReorderEnd = () => {
    onListReorderEnd && onListReorderEnd();
    draggedElementId.current = undefined;
    dragStage.current = 0;
  }
  
  if(disabled)
    return (
      <div
        className={classnames(
          displayAsGrid && classes.grid,
          displayAsFlexbox && classes.flexbox,
          displayAsColumnFlexbox && classes.columnFlexbox,
          className
        )}
        style={style}
      >
        {children}
      </div>
    )
  
  return (
    <ReactSortable
      id={path}
      list={list?.map((item) => ({ id: item[itemIdKey] }))}
      group={group}
      setList={handleListReorder}
      onStart={handleListReorderStart}
      onEnd={handleListReorderEnd}
      onAdd={onListAdd}
      onRemove={onListRemove}
      disabled={disabled || undefined}
      handle={useHandle ? ".dragHandle" : undefined}
      filter=".noDrag"
      ghostClass="sortable-placeholder"
      scroll
      bubbleScroll
      // scrollSensitivity={30}
      forceFallback//={forceFallback}
      animation={200}
      preventOnFilter={false}
      // delay={50}
      fallbackTolerance={10}
      // removeCloneOnHide={false}
      className={classnames(
        displayAsGrid && classes.grid,
        displayAsFlexbox && classes.flexbox,
        displayAsColumnFlexbox && classes.columnFlexbox,
        className
      )}
      style={style}
    >
      {children}
    </ReactSortable>
  );
};

SortableList.propTypes = {
  list: arrayOf(object),
  children: oneOfType([string, object, node]),
  group: string,
  onListAdd: func,
  onListRemove: func,
  onListReorderStart: func,
  onListReorder: func,
  onListReorderEnd: func,
  disabled: bool,
  useHandle: bool,
  className: string,
  style: object,
  path: string,
  forceFallback: bool,
  itemIdKey: string,
  displayAsGrid: bool,
  displayAsFlexbox: bool,
  displayAsColumnFlexbox: bool,
};
