import { bool } from "prop-types";
import { usePresetFieldNames, useStructureStore } from "@hooks";
import { ValueCell } from "@components";
import { observer } from "mobx-react";
import { CurrencyEditor } from "@dialogs";

export const UnitCostCell = observer(({
  allowEdition,
  isProposal,
  company,
}) => {
  const { getFieldName } = usePresetFieldNames(isProposal);
  const structure = useStructureStore();
  const { currency, companyCurrency } = structure.settings;
  
  return (
    <ValueCell
      widest
      isHeader
      size="xSmall"
      style={{ cursor: allowEdition && "grab" }}
    >
      <span>
        {`${getFieldName("pt-hourlyCost")} (${company ? companyCurrency : currency})`}
      </span>
      {
        company && allowEdition &&
        <CurrencyEditor useCompanyCurrency={company} />
      }
    </ValueCell>
  )
})

UnitCostCell.propTypes = {
  allowEdition: bool,
  isProposal: bool,
  company: bool,
}