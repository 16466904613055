import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { capitalize, Grid } from "@material-ui/core";
import { InfoLabel, ButtonSwitch, TooltipIcon, ClearInput } from "@components";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export const CurrencyExchangeSettings = observer(() => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const {
    currency,
    companyCurrency,
    useCustomExchangeRate,
    dailyExchangeRate,
    customExchangeRate,
  } = structure.settings;
  
  const handleCustomExchangeRate = (v) => {
    structure.settings.setCustomExchangeRate(v ? 1/v : 100);
  }
  
  const value = useMemo(() => 1 / (
    useCustomExchangeRate && typeof customExchangeRate === "number"
      ? customExchangeRate
      : dailyExchangeRate || 1
  ), [useCustomExchangeRate, customExchangeRate, dailyExchangeRate])
  
  return <Grid item container wrap="nowrap" alignItems="center" className="mt-6">
    <InfoLabel
      className="my-0 mr-4 w-max"
      label={t("views.editor.summary_table.currency_exchange.title", { companyCurrency, currency })}
      translate={false}
    />
    <ButtonSwitch
      value={useCustomExchangeRate || false}
      setValue={structure.settings.setUseCustomExchangeRate}
      values={[
        { value: false, label: capitalize(t("common.auto")) },
        { value: true, label: capitalize(t("common.custom")) },
      ]}
      size="small"
    />
    <ClearInput
      value={value}
      onChange={handleCustomExchangeRate}
      addInitialBorder
      fixedDecimalScale
      className="w-16 mx-2"
      decimals={2}
      disabled={!useCustomExchangeRate}
    />
    <TooltipIcon color="primary">
      {t("views.editor.summary_table.currency_exchange.tooltip")}
    </TooltipIcon>
  </Grid>
})