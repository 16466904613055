import { useEffect, useRef, useState } from "react";

export const useTableOverflowChecker = (tableContentId, onResize) => {
  const [isTableContentOverflowing, setOverflowing] = useState(false);
  const [scrollReachedStart, setReachedStart] = useState(false);
  const [scrollReachedEnd, setReachedEnd] = useState(false);
  
  const containerRef = useRef(null);
  
  const handleScroll = () => {
    if(!containerRef.current) return;
    const s = containerRef.current.scrollLeft;
    const w = containerRef.current.scrollWidth - containerRef.current.offsetWidth;
    if(s <= 0 || scrollReachedStart)
      setReachedStart(s <= 0)
    if(s >= w || scrollReachedEnd)
      setReachedEnd(s >= w)
  }

  const handleResize = () => {
    if(!containerRef.current) return;
    const b = document.getElementById(tableContentId);

    if (b) {
      const o = containerRef.current.offsetWidth < b.offsetWidth;
      if (o !== isTableContentOverflowing) setOverflowing(o);
      
      onResize?.(b, containerRef.current.offsetWidth);
    }
    
    handleScroll();
  };

  useEffect(() => {
    handleResize();
  }, []);

  return {
    ref: containerRef,
    onScroll: handleScroll,
    onResize: handleResize,
    isTableContentOverflowing,
    scrollButtonProps: {
      containerRef,
      scrollReachedStart,
      scrollReachedEnd,
    }
  };
};
