import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    padding: 6,
    height: 32,
    maxWidth: 82,
    fontFamily: "inherit !important",
    backgroundColor: "transparent !important",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    borderRadius: 5,
    "&:focus::placeholder, input:focus::-webkit-input-placeholder, input:focus:-moz-placeholder, input:focus:-moz-placeholder": {
      color: "transparent",
    },
    fontWeight: "inherit",
    fontSize: "inherit",
    color: "inherit",
    "&::placeholder": {
      color: theme.props.text.lighter
    }
  },
  colorPrimary: {
    "&:hover, &:focus": { borderColor: theme.palette.primary.main },
  },
  colorWarning: {
    "&:hover, &:focus": { borderColor: theme.palette.warning.main },
  },
  colorError: {
    "&:hover, &:focus": { borderColor: theme.palette.error.main },
  },
  colorText: {
    "&:hover, &:focus": { borderColor: theme.palette.text.primary },
  },
  sizeSmall: {
    height: 24
  },
  cappedWidth: {
    maxWidth: "100%",
  },
  fullWidth: {
    maxWidth: "100%",
  },
  maxWidth: {
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0, top: 0,
  },
  minWidth: {
    minWidth: 48,
  },
  addInitialBorder: {
    borderColor: theme.props.border,
  },
  disabled: {
    color: theme.palette.text.lighter,
  },
  disabledHover: {
    "&:hover": {
      borderColor: "transparent !important"
    },
  },
  left: { textAlign: "left" },
  center: { textAlign: "center"},
  right: { textAlign: "right" },
}));

export default useStyle;
