import { useMemo, useState } from "react";
import {
  bool,
  number,
  string,
  object,
  node,
  arrayOf,
  oneOfType,
  oneOf,
} from "prop-types";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { hashCode } from "project-structure";
import { useTranslation } from "react-i18next";
import { useEditorWebsocket, useStructureStore } from "@hooks";
import { Alert, SortableList } from "@components";
import { BreakdownRowGroup } from "./BreakdownRowGroup/BreakdownRowGroup";

export const BreakdownRowGroupList = observer(({
  element,
  allowEdition,
  isLastGroupRow,
  parentIds,
  children,
  tableDepth = 0,
  initialDepth = 0,
  ...elementProps
}) => {
  const structure = useStructureStore();
  const socket = useEditorWebsocket();
  const { t } = useTranslation();

  const [moveAlertVisible, showMoveAlert] = useState(false);
  const [mergeData, setMergeData] = useState(undefined);

  const { children: elementChildren } = element;

  const actualDepthLevel = useMemo(() => tableDepth + initialDepth, []);

  const handleDragAdd = (e) => {
    if (!e.from.id) return;

    const movedTask = structure.getByHashedIdPath(
      ...e.from.id.split("/"),
      e.item.dataset.id
    );
    if (!movedTask) return;

    const target = element.getExistingChild(
      movedTask.id,
      movedTask.componentId,
      movedTask.name
    );
    
    structure.historyManager.startGroup();
    if (target) {
      showMoveAlert(true);
      movedTask.switchParentBlockChildReorder();
      
      setMergeData({
        target,
        movedTask,
      });
    } else {
      element.pushChild(movedTask.id, getSnapshot(movedTask), e.newIndex);
      socket?.requestCommentMove(
        movedTask.treePath.join("/"),
        [...element.treePath, ...movedTask.treePath.slice(element.treePath.length)].join("/"),
      )
    }
  };

  const hideMoveAlert = () => {
    showMoveAlert(false);
  };

  const mergeChildren = () => {
    hideMoveAlert();
    if (!mergeData) return;
    structure.historyManager.startGroup();
    mergeData.target.mergeWithOtherTask(getSnapshot(mergeData.movedTask));
    socket?.requestCommentMove(
      mergeData.movedTask.treePath.join("/"),
      mergeData.target.treePath.join("/"),
    )
    mergeData.movedTask.removeSelf();
    setMergeData(undefined);
    structure.historyManager.stopGroup();
  };

  const handleChildrenReorder = (list, draggedId) => {
    if(list.length === elementChildren.length)
      structure.historyManager.startGroup();
    element.reorderChildren(list, draggedId);
    structure.historyManager.stopGroup();
  };

  const path = useMemo(
    () => parentIds.map((id) => hashCode(id)).join("/"),
    [parentIds]
  );

  return (
    <>
      <SortableList
        list={elementChildren}
        path={path}
        group={`breakdown-d${actualDepthLevel}`}
        onListReorder={handleChildrenReorder}
        onListAdd={handleDragAdd}
        displayAsColumnFlexbox
        disabled={!allowEdition}
      >
        {elementChildren?.map((child, i) => (
          <BreakdownRowGroup
            key={`element${child.id}`}
            element={child}
            allowEdition={allowEdition}
            isLastGroupRow={
              isLastGroupRow &&
              (tableDepth === 0 || i === elementChildren.length - 1)
            }
            parentIds={parentIds}
            tableDepth={tableDepth + 1}
            initialDepth={initialDepth}
            {...elementProps}
          />
        ))}
      </SortableList>
      {children}
      <Alert
        isOpen={moveAlertVisible}
        title={t(
          "views.editor.dialogs.move_alert.title_level" + (tableDepth + 1)
        )}
        onAccept={mergeChildren}
        onCancel={hideMoveAlert}
        acceptText={t("views.editor.dialogs.move_alert.confirm")}
        cancelText={t("common.cancel")}
      >
        {t("views.editor.dialogs.move_alert.subtitle")}
      </Alert>
    </>
  );
});

BreakdownRowGroupList.propTypes = {
  /** TaskModel | SectionModel */
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]),
  initialDepth: oneOf([0, 1, 2, 3]),

  blockExpansions: bool,
  displayStatus: bool,
  allowStatusChange: bool,
  isLastGroupRow: bool,

  parentIds: arrayOf(oneOfType([number, string])),
  parentName: string,
  children: node,

  //common
  allowEdition: bool,
  isSellerOrClient: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  showCombinedHours: bool,
};
