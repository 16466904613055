import { createContext } from "react";
import { instanceOf, node } from "prop-types";
import { observable, action, makeObservable, computed } from "mobx";

//-------------------------------------------------------------------------------------------
export class ActiveProjectStore {
  constructor() {
    makeObservable(this);
  }

  @observable projects = [];
  @observable workTypes = [];
  @observable currentProjectUuid;

  @computed get currentProject() {
    if(!this.currentProjectUuid) return;
    return this.projects.find((project) => project.uuid === this.currentProjectUuid);
  }

  @action setCurrentProject(currentProjectUuid) {
    this.currentProjectUuid = currentProjectUuid;
  }

  @action setProjects(projects) {
    this.projects = projects;
  }
  
  @action setWorkTypes(workTypes) {
    this.workTypes = workTypes;
  }
  
  @action setBtProjectsStatus(jiraProjects) {
    jiraProjects.forEach(({ apropoId, setUp, mapping }) => {
      const p = this.projects.find(p => p.id === apropoId);
      if(p) {
        p.btExported = mapping !== null;
        p.btSetUp = Boolean(setUp);
      }
    })
  }
  
  @action addProject(project) {
    if(this.projects.find(p => p.uuid === project.uuid))
      return;
    this.projects = [...this.projects, project];
  }
  
  
  @action removeProject(uuid) {
    this.projects = this.projects.filter((pr) => pr.uuid !== uuid);
  }
  
  @action updateLocalProject(uuid, data) {
    const project = this.projects.find((p) => p.uuid === uuid);
    Object.keys(data).forEach((key) => {
      project[key] = data[key];
    });
  }

  @action editLocalProjectData(uuid, action, data) {
    let tmp = [...this.projects];
    let project = tmp.find((p) => p.uuid === uuid);
    switch (action) {
      case "attachmentAdd":
        project.attachments.push({
          ...data,
          type: "attachment",
        });
        break;
      case "attachmentRemove":
        project.attachments = project.attachments.filter(
          (a) => a.uuid !== data
        );
        break;
      case "status":
        project.status = data.status;
        project.order = data.order;
        // status, order, sourceStatus, sourceOrder
        tmp.forEach((pr) => {
          if(pr.uuid === project.uuid)
            return;
          if(pr.status === data.sourceStatus && pr.order >= data.sourceOrder) {
            pr.order--; // fill the gap
          }
          if(pr.status === data.status && pr.order >= data.order) {
            pr.order++; //give space for new item
          }
        });
        break;
      case "observers":
        project.users.observer = data;
        break;
      case "owner":
        project.users.owner = data;
        break;
      case "archive":
      case "remove":
        tmp = tmp.filter((pr) => pr.uuid !== uuid);
        break;
      default:
        project[action] = data;
    }
    this.projects = tmp;
  }

  // -------------------------------------------------------------------------------------------
  // new project steps
  // @observable projectId = null;
  //
  // @action clearProjectData() {
  //   this.projectTemplate = null;
  //   this.projectComplexity = null;
  //   this.projectDesc = "";
  //   this.projectName = "";
  //   this.projectType = "standard";
  //   this.projectSystems = [];
  //   this.projectPdfProposal = [];
  //   this.projectStructure = null;
  // }
  //
  // // project form data
  // @observable projectStatus = "todo";
  // @action setProjectStatus = v => this.projectStatus = v;
  //
  // @observable projectName = "";
  // @action setProjectName = v => this.projectName = v || "";
  //
  // @observable projectSystems = [];
  // @action setProjectSystems = v => this.projectSystems = v;
  // @action removeProjectSystem = v => this.projectSystems = this.projectSystems.map(p => ({...p})).filter(p => p.id !== v);
  //
  // @observable projectDesc = "";
  // @action setProjectDesc = v => this.projectDesc = v;
  //
  // @observable projectAttachments = [];
  // @action setProjectAttachments = v => this.projectAttachments = v;
  //
  // @observable projectPdfProposal = [];
  // @action setProjectPdfProposal = v => this.projectPdfProposal = v;
  //
  // @observable projectType = "standard";
  // @action setProjectType = v => this.projectType = v;
  //
  // @observable projectTemplate = null;
  // @action setProjectTemplate = (v=null) => {
  //   if(this.projectTemplate !== v) {
  //     this.projectTemplate = v;
  //     this.projectComplexity = null;
  //   }
  // };

  /** @note: unused ~23.03.2023 */
  // @observable projectComplexity = null;
  // @action setProjectComplexity = v => this.projectComplexity = v;
}

export const ActiveProjectStoreContext = createContext(null);

export const ActiveProjectStoreProvider = ({ children, value }) => (
  <ActiveProjectStoreContext.Provider value={value}>
    {children}
  </ActiveProjectStoreContext.Provider>
);

ActiveProjectStoreProvider.propTypes = {
  children: node.isRequired,
  value: instanceOf(ActiveProjectStore).isRequired,
};
