import { useState } from "react";
import { bool, string, instanceOf, number } from "prop-types";
import { useActiveProjectStore } from "@hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changeProjectDeadlineQuery } from "@query";
import { Grid, IconButton } from "@material-ui/core";
import { ArrowBack } from "@assets";
import { format, set } from "date-fns";
import { Alert, Deadline, StatusBullet, ProjectActionMenu } from "@components";
import { NameEditor } from "../index";
import classnames from "classnames";
import useStyle from "./Header.style";
import { home } from "@paths";

export const Header = ({
  uuid,
  name,
  created,
  deadline,
  status,
  isMini,
  isOwner,
  showActionMenu,
  ...actionMenuProps
}) => {

  const activeProjects = useActiveProjectStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyle();

  const [alertText, setAlertText] = useState("");

  const handleDateChange = async (date) => {
    try {
      const d = format(
        set(date, { hours: 0, minutes: 0, seconds: 0 }),
        "yyyy-MM-dd HH:mm:ss"
      );
      await changeProjectDeadlineQuery(uuid, d);
      activeProjects.editLocalProjectData(uuid, "dueOn", d);
    } catch (e) {
      setAlertText(e.response.data.error);
    }
  };

  return (
    <>
      <Grid
        item
        container
        direction="column"
        className={classes.headerContainer}
      >
        <Grid item container justifyContent="space-between">
          <NameEditor name={name} uuid={uuid} allowEdition={isOwner} />
          {showActionMenu && (
            <Grid item>
              <ProjectActionMenu
                useCreateTemplate
                isOwner={isOwner}
                isMini={isMini}
                uuid={uuid}
                name={name}
                {...actionMenuProps}
              />
              <IconButton
                onClick={() => navigate(home)}
                color="primary"
                style={{ marginLeft: 8 }}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          spacing={1}
          alignItems="center"
          style={{ marginTop: 4 }}
        >
          <Grid item>
            <Deadline
              date={deadline}
              status={status}
              onEdit={isOwner ? handleDateChange : undefined}
            />
            {isOwner && (
              <Alert
                title={alertText}
                isOpen={!!alertText}
                onAccept={() => setAlertText("")}
                acceptText={t("common.close")}
              />
            )}
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={{ width: "max-content" }}
          >
            <StatusBullet className={classes.opaque} size="xs" />
          </Grid>
          <Grid item>
            <p className={classnames(classes.opaque, classes.text)}>
              {t(`project_types.${isMini ? "mini" : "standard"}`)}
            </p>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={{ width: "max-content" }}
          >
            <StatusBullet className={classes.opaque} size="xs" />
          </Grid>
          <Grid item>
            <p className={classnames(classes.opaque, classes.text)}>
              {`${t("common.created_on")} ${format(created, "dd MMM yyyy")}`}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Header.propTypes = {
  id: number.isRequired,
  uuid: string.isRequired,
  isOwner: bool.isRequired,
  isMini: bool.isRequired,
  isBusiness: bool.isRequired,
  deadline: instanceOf(Date).isRequired,
  created: instanceOf(Date).isRequired,
  status: string.isRequired,
  description: string,
  name: string,
  showActionMenu: bool,
  projectBtSetUp: bool,
  projectBtExported: bool,
};
