import { useEffect, useMemo, useState, useRef } from "react";
import { generateEntryId } from "project-structure";
import {LIBRARY_LEVELS, MAX_MODULE_NAME} from "@utils";
import { searchLibraryComponentsQuery } from "@query";
import { useStructureStore } from "@hooks";

let queryTimeout;

export const useTaskSelect = ({ tableDepth, element }) => {
  const { id, name, autoFocus } = element;
  
  const structure = useStructureStore();

  const [searchValue, setSearchValue] = useState("");
  const [taskNameAlreadyUsed, setUsedValue] = useState(false);
  const [unmatchableText, setNoDataValue] = useState("");
  const [currentPage, setPage] = useState(false);
  const [hasMorePages, setMorePages] = useState(false);
  const [elements, setElements] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [focused, setFocused] = useState(undefined);

  const anchorEl = useRef(null);

  const noMatchesFound = useMemo(
    () =>
      unmatchableText &&
      searchValue?.length > unmatchableText.length &&
      searchValue.slice(0, unmatchableText.length) === unmatchableText,
    [unmatchableText, searchValue]
  );

  useEffect(() => {
    if (autoFocus) {
      setFocused(true);
      element.removeAutofocus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (focused && (searchValue?.length >= 3 || !searchValue?.length)) {
      if (!noMatchesFound) {
        setUsedValue(false);
        if (queryTimeout) clearTimeout(queryTimeout);
        queryTimeout = setTimeout(() => {
          loadElements(true);
        }, 500);
      }
    }

    if (searchValue?.length && searchValue !== name)
      setUsedValue(element.hasSibling(searchValue));
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(name?.replace(/<(.|\n)*?>/g, ""));
  }, [name]);

  useEffect(() => {
    if (focused) loadElements(true, true);
    // if(focused !== undefined)
    // 	setFocusSyncMessage(focused);
    // setSearchValue(name);
  }, [focused]);

  // const setFocusSyncMessage = (state) => {
  // 	websocket?.sendSyncMessage("task_name_focus", state, fullIdPath);
  // };

  const loadElements = async (resetList = false, noFilter = false) => {
    if (isLoading || tableDepth === 0) return;

    setLoading(true);
    const {
      elements: newElements,
      page,
      pages,
    } = await searchLibraryComponentsQuery(
      LIBRARY_LEVELS[tableDepth - 1],
      resetList ? 1 : currentPage + 1,
      element.parentComponentId,
      noFilter ? "" : searchValue
    );
    setElements(resetList ? newElements : [...elements, ...newElements]);
    setPage(page);
    setMorePages(pages > page);
    setNoDataValue(newElements.length === 0 ? searchValue : "");
    setLoading(false);
  };

  /**
   * Finds best match for provided "searchValue"; triggers on input blur
   */
  const handleModuleSelect = async () => {
    if (focused) {
      setFocused(false);
      if (taskNameAlreadyUsed) {
        setUsedValue(false);
        setSearchValue(name);
      } else if (!id || searchValue !== name) {
        // const matchedModule = elements.find(({name}) => simplifyTextString(name) === simplifyTextString(searchValue));
        // if(matchedModule)
        // 	await handleNameChange(matchedModule);
        // else
        await handleNameChange({ name: searchValue }, true);
      }
    }
  };

  const handleInputChange = (v) => {
    setSearchValue(v.slice(0, MAX_MODULE_NAME));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleScrollEnd = () => {
    if (hasMorePages) loadElements();
  };

  const handleNameChange = async (
    newElement,
    isCustomTask = false /*, category*/
  ) => {
    const { name, content, id, componentId, children, values } = newElement;
    structure.historyManager.startGroup();

    setFocused(false);
    // setFocusSyncMessage(false);
    element.setName(
      name.trim(),
      isCustomTask || !id ? element.id || generateEntryId() : id,
      componentId
    );

    if (tableDepth === 0) {
      structure.historyManager.stopGroup();
      return;
    }

    if (componentId) {
      if (!element.content && content) element.setContent(content, false);
      if (values) element.setValues(values, true);
      if (children?.length)
        element.addChildren(children.map(populateWithEntryIds));
    }
    structure.historyManager.stopGroup();
  };

  return {
    isLoading,
    elements,
    focused,
    taskNameAlreadyUsed,
    searchValue,
    handleInputChange,
    handleFocus,
    handleNameChange,
    handleScrollEnd,
    handleModuleSelect,
    anchorEl,
  };
};

const populateWithEntryIds = (child, i) => {
  if (child.children) child.children = child.children.map(populateWithEntryIds);

  child.id = generateEntryId(i);

  return child;
};
