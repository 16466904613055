import { useState } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useEditorCommentStore, useEditorWebsocket, useProjectEditorStore } from "@hooks";
import { Estimate, ChatThread, TimelineSettings } from "@dialogs";
import { Alert, TooltipIcon, VisibilityButton } from "@components";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Trash, Percent } from "@assets";
import classnames from "classnames";

export const SummaryRowActions = observer(({
  workType,
  showChatThread,
  allowCommenting,
  allowEdition,
  opened
}) => {
  const { t } = useTranslation();
  const { visibilityMode } = useProjectEditorStore();
  const commentStore = useEditorCommentStore();
  const socket = useEditorWebsocket();

  const [estimateDialogOpened, openEstimateDialog] = useState(false);
  const [removeAlertOpened, openRemoveAlert] = useState(false);
  const commentPath = workType.treePath.join("/");
  
  const { isEmpty, inBreakdown, percent, isHidden } = workType;
  
  const commitRemoval = async () => {
    workType.removeSelf();
    openRemoveAlert(false);
    socket?.requestCommentPathRemove(commentPath);
    socket?.requestCommentPathRemove("t"+commentPath);
  };

  const handleBreakdownVisibility = () =>
    workType.setBreakdownVisibility(!inBreakdown);

  const handleEstimateDialog = () =>
    openEstimateDialog(!estimateDialogOpened);

  const handleRemoveAlert = () => openRemoveAlert(!removeAlertOpened);

  return (
    <>
      {allowEdition && (
        <>
          <TooltipIcon
            icon={<Trash color="error" />}
            onClick={isEmpty ? commitRemoval : handleRemoveAlert}
            className="button-remove noDrag hoverIcon opaque"
          >
            {t("views.editor.remove_workType")}
          </TooltipIcon>
          {!isEmpty && (
            <>
              {!inBreakdown && (
                <TooltipIcon
                  icon={<Percent />}
                  onClick={handleEstimateDialog}
                  color="primary"
                  className={classnames("button-automation noDrag", !percent && "hoverIcon opaque")}
                >
                  {t("views.editor.automation")}
                </TooltipIcon>
              )}
              <TooltipIcon
                icon={inBreakdown ? <Visibility /> : <VisibilityOff />}
                color="primary"
                onClick={handleBreakdownVisibility}
                className={classnames("button-hide noDrag", inBreakdown && "hoverIcon opaque")}
              >
                {t(
                  `views.editor.summary_table.${
                    inBreakdown ? "hide" : "show"
                  }_system`
                )}
              </TooltipIcon>
            </>
          )}
          {
            allowEdition &&
            <TimelineSettings
              selectedWorkType={workType.id}
              className="button-wta-settings noDrag hoverIcon opaque"
            />
          }
          {estimateDialogOpened && (
            <Estimate
              open={true}
              onClose={handleEstimateDialog}
              workType={workType}
            />
          )}
          <Alert
            isOpen={removeAlertOpened}
            title={t("alerts.editor.tag_remove")}
            onAccept={commitRemoval}
            onCancel={handleRemoveAlert}
            acceptText={t("common.remove")}
            cancelText={t("common.cancel")}
          />
        </>
      )}
      {
        visibilityMode &&
        <VisibilityButton
          hidden={isHidden}
          onChange={workType.setHideWorkType}
          inRow
        />
      }
      {
        showChatThread && commentStore?.commentsVisible &&
        <ChatThread
          rowThread
          path={commentPath}
          hideBadge={opened}
          allowCommenting={allowCommenting}
        />
      }
    </>
  );
});

SummaryRowActions.propTypes = {
  workType: object.isRequired,
  allowEdition: bool,
  showChatThread: bool,
  allowCommenting: bool,
  opened: bool,
};
