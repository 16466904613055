import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { TotalContainer } from "../components";

export const TotalCost = observer(({
  revenue,
  ...containerProps
}) => {
  const structure = useStructureStore();
  
  const {
    settings,
    visibility,
    displayTotalPrice,
    displayTotalPriceMax,
    showMaxTotalPrice,
  } = structure;
  const { currencyObj, roundPrice } = settings;
  const { hideTotalCost } = visibility;
  const className = useVisibilityModeCellClassName(hideTotalCost);
  
  return (
    <TotalContainer
      commentPath="cost"
      totalName={ revenue ? "Revenue" : "Cost" }
      min={displayTotalPrice}
      max={displayTotalPriceMax}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showMaxValue={showMaxTotalPrice}
      textSmaller={revenue}
      showDecimals={!roundPrice}
      allowNegative
      className={className}
      visibilityHidden={hideTotalCost}
      setVisibility={visibility.setHideTotalCost}
      {...containerProps}
    />
  );
})

TotalCost.propTypes = {
  isProposal: bool,
  divider: bool,
  revenue: bool,
  showChatThread: bool,
}