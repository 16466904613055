import { bool, number, string } from "prop-types";
import { Row } from "../Row/Row";
import { ValueCell, PriceCell } from "../../cells";

export const SummaryRow = ({
  children,
  displayMinMax,
  emptyValueCells,
  emptyPriceCell,
  useVisibilityCell,
  className,
  priceClassName,
  ...rowProps
}) => {

  return (
    <Row
      {...rowProps}
      noArrow
      hideVisibilitySwitch
      useVisibilityCell={useVisibilityCell}
      className={className}
    >
      {children}
      {emptyValueCells >= 0 &&
        [...new Array(emptyValueCells)].map((_, i) => (
          <ValueCell {...rowProps} wider={displayMinMax} key={i} />
        ))}
      {emptyPriceCell && (
        <PriceCell
          {...rowProps}
          wider={displayMinMax}
          extendPadding={!useVisibilityCell}
          className={priceClassName}
        />
      )}
    </Row>
  );
};

SummaryRow.propTypes = {
  ...Row.props,
  displayMinMax: bool,
  emptyValueCells: number,
  emptyPriceCell: bool,
  priceClassName: string,
};
