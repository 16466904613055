import { APIMethod, fetchData } from "@client";
import { zipStructure } from "project-structure";

export async function checkProjectAccessQuery(uuid, companySettings) {
  return await fetchData(
    `/v2/permit/project/access/${uuid}`,
    APIMethod.GET,
    {
      params: {
        settings: companySettings?.length ? zipStructure(JSON.stringify(companySettings)) : null
      },
    }
  );
}
