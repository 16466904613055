import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { parsePrice, roundFloat } from "project-structure";
import { CELL_HEIGHTS } from "@utils";
import { useStructureStore } from "@hooks";
import { Button } from "@components";
import { Collapse, Grid, Tooltip } from "@material-ui/core";
import { BillingChartBarInner } from "./BillingChartBarInner";
import { BillingChartBarTooltipContent } from "./BillingChartBarTooltipContent";
import useStyle from "./BillingChartBar.style";
import classnames from "classnames";
import { difference, intersection } from "lodash";

export const BillingChartBar =  observer(({
  values,
  onOpen,
  blockExpansions,
}) => {
  const classes = useStyle();
  const structure = useStructureStore();
  
  const { timelineBillingScale, settings } = structure;
  const { currencyObj, modifier, roundPrice, roundHours, timeModifier } = settings;
  const { topValue } = timelineBillingScale;
  
  const [opened, setOpened] = useState(false);
  const [workTypeIds, setWorkTypeIds] = useState([]);
  
  useEffect(() => {
    const newIds = values.map(v => v.id);
    if(newIds?.length !== workTypeIds.length || intersection(newIds, workTypeIds).length !== workTypeIds.length) {
      if(opened) {
        onOpen(true, difference(newIds, workTypeIds));
        onOpen(false, difference(workTypeIds, newIds));
      }
      setWorkTypeIds(newIds);
    }
    // setWorkTypeIds(newIds);
  }, [values])
  
  const sortedValues = useMemo(() => (
    values.sort((a,b) => a.value > b.value ? 1 : -1)
  ), [values])
  
  const combinedValue = useMemo(() => (
    values.reduce((t, v) => t + v.value, 0)
  ), [values]);
  
  const heights = useMemo(() => (
    sortedValues.map((v) => (
      topValue
        ? (4 * CELL_HEIGHTS.XXLARGE - 8) * Number((v.value / topValue).toPrecision(12))
        : 0
    ))
  ), [sortedValues, topValue])
  
  const height = useMemo(() => (
    heights.reduce((t, v) => t + v, 0)
  ), [heights])
  
  const handleOpen = () => {
    if(blockExpansions) return;
    setOpened(!opened);
    onOpen(!opened, values.map(v => v.id));
  }
  
  return (
    <Grid
      item container
      direction="column"
      className={classnames(classes.container)}
    >
      {
        combinedValue > 0 &&
        <Grid
          item container
          justifyContent="center"
          className="color-primary text-xs mb-2 text-nowrap"
        >
          {currencyObj.symbolStart || ""}
          {parsePrice(roundFloat(combinedValue, roundPrice ? 3 : 0, !roundPrice))}
          {currencyObj.symbolEnd || ""}
        </Grid>
      }
      <Tooltip
        placement="top"
        title={(
          opened
            ? ""
            : <BillingChartBarTooltipContent
              values={values}
              roundPrice={roundPrice}
              currencyObj={currencyObj}
            />
        )}
      >
        <Button
          onClick={handleOpen}
          style={{ height }}
          color="primary"
          variant="contained"
          className={classnames( "p-0 overflow-hidden", classes.block, opened && classes.blockOpened )}
        >
          <Collapse
            in={opened}
            mountOnEnter
            unmountOnExit
            className="w-full"
          >
            <Grid item container direction="column">
              {
                sortedValues
                  .map((v, i) => (
                    <BillingChartBarInner
                      key={v.name}
                      { ...v }
                      hours={roundFloat(v.hours / modifier, roundHours)}
                      height={heights[i]}
                      currencyObj={currencyObj}
                      roundPrice={roundPrice}
                      timeModifier={timeModifier}
                    />
                  ))
              }
            </Grid>
          </Collapse>
        </Button>
      </Tooltip>
    </Grid>
  );
});

BillingChartBar.propTypes = {
  onOpen: func.isRequired,
  blockExpansions: bool,
  values: arrayOf(shape({
    id: number.isRequired,
    value: number.isRequired,
    hours: number.isRequired,
    name: string.isRequired,
    color: string,
  })),
};