import {useEffect, useMemo, useState} from "react";
import { bool, string, func } from "prop-types";
import {
  useActiveProjectStore,
  useCheckProjectAccess,
  useStores,
} from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Grid, Hidden } from "@material-ui/core";
import { Dialog, InfoLabel, ClearButton } from "@components";
import {
  DescriptionEditor,
  ProjectChat,
  Header,
  Footer,
  ActionPanel,
} from "./components";
import { Comment } from "@assets";
import useStyle from "./ProjectEditionDialog.style";
import { universalDateParser } from "@utils";
import { getProjectDetailsQuery, getTeamMembersQuery } from "@query";

export const ProjectEditionDialog = observer(({
  isOpen,
  handleModalOpenState,
  projectUuid,
  noDialog
}) => {

  const { userStore } = useStores();
  const activeProjects = useActiveProjectStore();
  const { t } = useTranslation();
  const classes = useStyle(isMobile);

  const [showCommentInput, setShowCommentInput] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  const {
    id,
    uuid,
    createdAt,
    dueOn,
    name,
    tags,
    users,
    content,
    attachments,
    type,
    status,
    btSetUp,
    btExported,
  } = activeProjects.currentProject;

  const { isOwner, isObserver } = useCheckProjectAccess(users);

  const deadline = useMemo(() => (
    dueOn ? universalDateParser(dueOn) : undefined
  ), [dueOn]);

  const created = useMemo(() => (
    createdAt ? universalDateParser(createdAt) : undefined
  ), [createdAt]);

  const isMini = useMemo(() => type === "mini", []);

  const pdfProposals = attachments
    ? attachments.filter((x) => x.type === "proposal")
    : [];

  const fileAttachments = attachments
    ? attachments.filter((x) => x.type !== "proposal")
    : [];

  const chatUsers = [
    ...users.observer,
    users.author,
    ...(users.owner && users.owner.id !== users.author.id ? [users.owner] : []),
  ].filter((u) => u.id !== userStore.data.id);


  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const details = await getProjectDetailsQuery(projectUuid);
    const u = await getTeamMembersQuery(userStore.workspaceUuid);
    setTeamMembers(u);
    activeProjects.updateLocalProject(projectUuid, details);
  }

  const ActionPanelWithProps = (sticky = false) => (
    <ActionPanel
      uuid={uuid}
      isOwner={isOwner}
      isMini={isMini}
      attachments={isMini ? pdfProposals : fileAttachments}
      author={users.author}
      owner={users.owner}
      users={users.observer}
      teamMembers={teamMembers}
      tags={tags}
      sticky={sticky}
    />
  );

  const EditorContent = (
    <Grid
      item
      container
      direction="column"
      wrap="nowrap"
      className={classes.scrollbarContainer}
    >
      <InfoLabel label="views.active.popup.desc" />
      <DescriptionEditor
        description={content || ""}
        uuid={projectUuid}
        allowEdition={isOwner}
      />
      {noDialog ? (
        ActionPanelWithProps()
      ) : (
        <Hidden smUp>{ActionPanelWithProps()}</Hidden>
      )}

      <InfoLabel
        label="views.active.popup.activity"
        action={
          isObserver ? (
            <ClearButton
              icon={<Comment />}
              onClick={() => setShowCommentInput(true)}
            >
              {t("views.active.popup.add_comment")}
            </ClearButton>
          ) : undefined
        }
      />
      <ProjectChat
        projectUuid={uuid}
        noAdding={!isObserver}
        users={chatUsers}
        teamMembers={teamMembers}
        showCommentInput={showCommentInput}
        onCommentCommit={() => setShowCommentInput(false)}
      />
    </Grid>
  );

  return noDialog ? (
    <>
      <Grid item container className={classes.noDialogHeader}>
        <Header
          uuid={uuid}
          name={name}
          description={content}
          isOwner={isOwner}
          isMini={isMini}
          isBusiness={userStore.isBusinessUser}
          deadline={deadline}
          created={created}
          status={status}
          id={id}
          projectBtSetUp={btSetUp}
          projectBtExported={btExported}
          showActionMenu
        />
      </Grid>
      {EditorContent}
    </>
  ) : (
    <Dialog
      open={isOpen}
      onClose={handleModalOpenState ? () => handleModalOpenState(false) : null}
      width={850}
      disableEnforceFocus
      title={
        <Header
          uuid={uuid}
          name={name}
          description={content}
          isOwner={isOwner}
          isMini={isMini}
          isBusiness={userStore.isBusinessUser}
          deadline={deadline}
          created={created}
          status={status}
          id={id}
          projectBtSetUp={btSetUp}
          projectBtExported={btExported}
        />
      }
      actions={<Footer
        uuid={uuid}
        isOwner={isOwner}
        isMini={isMini}
        isBusiness={userStore.isBusinessUser}
        name={name}
        description={content}
        id={id}
        projectBtSetUp={btSetUp}
        projectBtExported={btExported}
      />}
    >
      <Hidden xsDown>
        <Grid item container spacing={6}>
          <Grid item container xs={6} md={8}>
            {EditorContent}
          </Grid>
          <Grid item container xs={6} md={4} direction="column">
            {ActionPanelWithProps(true)}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>{EditorContent}</Hidden>
    </Dialog>
  );
});

ProjectEditionDialog.propTypes = {
  noDialog: bool,
  isOpen: bool,
  handleModalOpenState: func,
  projectUuid: string.isRequired,
};
