import { useMemo } from "react";
import { useStructureStore } from "@hooks";
import { useTranslation } from "react-i18next";

export const useSummaryTeamInputTooltipAlert = (allowEdition) => {
  
  const { t } = useTranslation();
  const structure = useStructureStore();
  const { isTimelineTableUsed } = structure.settings;
  
  const messageTitle = useMemo(() => {
    if(isTimelineTableUsed && allowEdition)
      return t("views.editor.timeline_team_alert.title")
  }, [isTimelineTableUsed]);
  
  const messageContent = useMemo(() => {
    if(isTimelineTableUsed && allowEdition)
      return t("views.editor.timeline_team_alert.content")
  }, [isTimelineTableUsed]);
  
  return {
    messageTitle,
    messageContent,
  }
}