import { useMemo } from "react";
import { bool, number } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { capitalize, Grid } from "@material-ui/core";
import { TooltipIcon, ValueCell } from "@components";
import { ExpandMore } from "@material-ui/icons";
import useStyle from "./TableHeaderCell.style";
import classnames from "classnames";

export const TableHeaderCell = observer(({
  monthIndex,
  isProposal,
  useClientActions
}) => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const classes = useStyle();
  
  const isOpened = useMemo(() => (
    structure.settings.isTimelineMonthExpanded(monthIndex)
  ), [structure.settings.expandedTimelineMonths?.size]);
  
  const handleExpand = () => {
    if(isProposal && !useClientActions) return;
    structure.settings.expandTimelineMonth(monthIndex, !isOpened);
  }
  
  return (
    <ValueCell
      isHeader
      // size="small"
      className={classnames(
        "p-0",
        classes.cellContainer,
        isOpened && classes.cellExpanded
      )}
    >
      <Grid item container direction="column">
        <span className="preset-tableTimelineHeader">
          {`${capitalize(t("time.month"))} ${monthIndex+1}`}
        </span>
        {
          isOpened &&
          <Grid item container wrap="nowrap" >
            { [ 1, 2, 3, 4 ].map(week => (
              <span key={week} className={classnames("preset-tableTimelineHeader my-1", classes.weekCell)}>
                {`${capitalize(t("time.week"))} ${week}`}
              </span>
            )) }
          </Grid>
        }
      </Grid>
      <TooltipIcon
        icon={<ExpandMore
          className={classnames("expand transition -rotate-90", isOpened && "rotate-90")}
        />}
        onClick={handleExpand}
        className={classnames(
          "noDrag absolute",
          classes.button,
          !isOpened && "cellHoverIcon",
          isOpened && classes.buttonOpened
        )}
        color="primary"
      >
        {t(`views.editor.timeline_table.${isOpened ? "hide" : "show"}_week`)}
      </TooltipIcon>
    </ValueCell>
  )
})

TableHeaderCell.propTypes = {
  monthIndex: number.isRequired,
  isProposal: bool,
  useClientActions: bool,
}