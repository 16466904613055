import { useMemo, useState } from "react";
import { bool, string, number } from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { IconButton } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { ArrowRight, Monitor } from "@assets";
import { PopMenu, MenuButton, Button } from "@components";
import { editor, pdfEditor, sessions } from "@paths";
import useStyle from "./ProjectActionMenu.style";
import { LOCKED_FEATURES } from "@utils";
import { useFeatureGuardian, useStores } from "@hooks";
import classnames from "classnames";
import {
  CreateTemplate,
  DuplicateProject,
  ArchiveProject,
  DeleteProject,
} from "./buttons";
import { checkBudgetTrackingQuery, checkSetUpQuery } from "@query";
import { zipStructure } from "project-structure";
import { JiraExportAndBTInfoDialog } from "@dialogs";
import { Jira } from "@assets";

export const ProjectActionMenu = ({
  id,
  uuid,
  isOwner,
  isBusiness,
  isMini,
  onlyDangerousActions,
  fullButton,
  useCreateTemplate,
  description,
  name,
  projectBtSetUp,
  projectBtExported,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore } = useStores();

  const { checkAccess: checkRecordAccess, FeatureAlert: RecordFeatureAlert } =
    useFeatureGuardian(LOCKED_FEATURES.RECORDINGS);

  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, show] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data } = userStore;
  
  const useBT = useMemo(() => (
    localStorage.getItem("new_jira_export")
  ), [])

  const clickAwayHandler = () => {
    show(false);
    // if (!archiveDialog && !deleteDialog) show(false);
  };

  const openMenu = () => {
    show(!visible);
  };

  const openEditor = () => {
    navigate(editor(uuid));
  };

  const openPdfEditor = () => {
    navigate(pdfEditor(uuid));
  };

  const openSessionList = async () => {
    const allowed = await checkRecordAccess();
    if (!allowed) return;
    navigate(sessions(uuid));
  };

  const openBudgetTracking = (e) => {
    e.stopPropagation();
    checkBudgetTrackingQuery(
      id,
      zipStructure(uuid),
      data.companyId
    );
  };

  const openSetUp = (e) => {
    if (!projectBtExported) {
      setOpenModal(true);
    } else {
      e.stopPropagation();
      checkSetUpQuery(id, zipStructure(uuid), data.companyId);
    }
  };

  return (
    <>
      {fullButton ? (
        <Button
          size="large"
          variant="contained"
          color="secondary"
          className="noDrag"
          ref={setAnchorEl}
          icon={<MoreHoriz />}
          onClick={openMenu}
        />
      ) : (
        <IconButton
          color="secondary"
          ref={setAnchorEl}
          onClick={openMenu}
          aria-label="show menu"
          className={classnames(classes.button, "noDrag")}
        >
          {<MoreHoriz />}
        </IconButton>
      )}

      <PopMenu
        show={visible}
        anchor={anchorEl}
        onClickAway={clickAwayHandler}
        style={{ width: 195 }}
      >
        {!onlyDangerousActions && (
          <MenuButton
            icon={<ArrowRight />}
            onClick={isMini ? openPdfEditor : openEditor}
          >
            {t("common.open")}
          </MenuButton>
        )}
        
        <MenuButton icon={<Jira />} onClick={openBudgetTracking}>
          {t("views.bt.export_title")}
        </MenuButton>

        {(!projectBtSetUp || !projectBtExported) && useBT && (
          <MenuButton icon={<Jira />} onClick={openSetUp}>
            {t("views.bt.import_title")}
          </MenuButton>
        )}
        {!onlyDangerousActions && !isMobile && isBusiness && (
          <MenuButton icon={<Monitor />} onClick={openSessionList}>
            {t("routes.recordings")}
          </MenuButton>
        )}
        {!isMini && useCreateTemplate && (
          <CreateTemplate name={name} uuid={uuid} description={description} />
        )}
        <DuplicateProject name={name} uuid={uuid} />
        {isOwner && (
          <>
            <ArchiveProject uuid={uuid} />
            <DeleteProject uuid={uuid} />
            <RecordFeatureAlert />
          </>
        )}
      </PopMenu>
      {openModal && (
        <JiraExportAndBTInfoDialog
          openModal={openModal}
          title={t("views.bt.open_export_bt_first")}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

ProjectActionMenu.propTypes = {
  id: number.isRequired,
  uuid: string.isRequired,
  isOwner: bool.isRequired,
  isBusiness: bool.isRequired,
  isMini: bool.isRequired,
  onlyDangerousActions: bool,
  fullButton: bool,
  useCreateTemplate: bool,
  description: string,
  name: string,
  projectBtSetUp: bool,
  projectBtExported: bool,
};
