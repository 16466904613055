import { bool, object, string } from "prop-types";
import { observer } from "mobx-react";
import { Columns } from "project-structure";
import { useStructureStore } from "@hooks";
import {
  CostCell,
  HourCell,
  RateCell,
  TimeCell,
  TeamMembersCell,
} from "./cells";
import {
  CompanyCostCell,
  CompanyUnitCostCell,
  ProfitabilityCell,
  ProfitCell
} from "../profitabilityCells";

export const ColumnFactory = observer(({
  name,
  isSellerOrClient,
  ...cellProps
}) => {
  const structure = useStructureStore();
  const { settings, visibility } = structure;
  const { hasTeamMembersCol, hasTimeCol, currency, companyCurrency } = settings;
  const {
    apply,
    hideSummaryTime,
    hideSummaryTeam,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryCost,
  } = visibility;
  
  switch (name) {
    case Columns.COST:
      return (cellProps.isProposal && apply && hideSummaryCost) || !isSellerOrClient
        ? <></>
        : <CostCell {...cellProps} />;
      
    case Columns.HOURS:
      return cellProps.isProposal && apply && hideSummaryHours
        ? <></>
        : <HourCell isWorkType {...cellProps} />;
      
    case Columns.RATE:
      return (cellProps.isProposal && apply && hideSummaryRate) || !isSellerOrClient
        ? <></>
        : <RateCell {...cellProps} />;
      
    case Columns.TEAM:
      return (cellProps.isProposal && apply && hideSummaryTeam) || !hasTeamMembersCol
        ? <></>
        : <TeamMembersCell {...cellProps} />;
      
    case Columns.TIME:
      return cellProps.isProposal && apply && hideSummaryTime
        ? <></>
        : hasTimeCol && <TimeCell {...cellProps} />;
      
    case Columns.C_COST:
      return isSellerOrClient && <CompanyCostCell {...cellProps} />;
    case Columns.C_UNIT_COST_INNER:
      return isSellerOrClient
        && <CompanyUnitCostCell useCompanyCurrency {...cellProps} />;
    case Columns.C_UNIT_COST:
      return isSellerOrClient && currency !== companyCurrency && <CompanyUnitCostCell {...cellProps} />;
    case Columns.C_PROFIT:
      return isSellerOrClient && <ProfitCell {...cellProps} />;
    case Columns.C_PROFITABILITY:
      return isSellerOrClient && <ProfitabilityCell {...cellProps} />;
  }
})

ColumnFactory.propTypes = {
  name: string.isRequired,
  element: object.isRequired,
  allowEdition: bool,
  isProposal: bool,
  isSellerOrClient: bool,
  displayCellContent: bool,
  displayClientCellContent: bool,
  useClientActions: bool,
  profitabilityMode: bool,
};
