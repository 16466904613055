import { memo } from "react";
import { bool, string, number, node, shape, oneOfType, oneOf, func } from "prop-types";
import { InputCellProps } from "../types";
import Grid from "@material-ui/core/Grid";
import { ValueCellReadOnlyContent } from "../components/ValueCellReadOnlyContent";
import { ValueCellEditableContent } from "../components/ValueCellEditableContent";
import useStyle from "./ValueCell.style";
import useCellStyle from "../commonStyles.style";
import classnames from "classnames";
import { capitalize } from "@material-ui/core";

export const ValueCell = memo(({
  onValueChange,
  displayContent,
  editable,
  wider,
  widest,
  narrow,
  mini,
  children,
  textPresetClass,
  useSeparator,
  messageContent,
  highlightCellText,
  className,
  style,
  isHeader,
  justifyCellContent="center",
  visible = true,
  size="large",
  valueContainerBackground,
  onMouseEnter,
  onMouseLeave,
  showValueSign,
  focusAction,
  ...valueProps
}) => {
  const classes = useStyle();
  const cellClasses = useCellStyle();

 let  modifiedSymbolStart = valueProps.symbolStart;

  if (valueProps.value !== 0 && showValueSign) {
    modifiedSymbolStart = (valueProps.value < 0 ? "-" : "+") + (valueProps.symbolStart || '');
  }

  return (
    <Grid
      item
      container
      role="rowitem"
      className={classnames(
        "cell",
        cellClasses.cell,
        classes.valueCell,
        useSeparator && cellClasses.withSeparator,
        mini && classes.cellMini,
        narrow && classes.cellSmall,
        wider && classes.cellMedium,
        widest && classes.cellLarge,
        isHeader && cellClasses.headCell,
        cellClasses[`size${capitalize(size)}`],
        highlightCellText && "highlightCellText",
        !visible && cellClasses.cellOff,
        className
      )}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Grid
        item container
        justifyContent={justifyCellContent}
        alignItems="center"
        wrap="nowrap"
        className={classnames(isHeader && "h-full", !isHeader && cellClasses.cellInner)}
      >
        {displayContent &&
          ((editable || messageContent || focusAction) ? (
            <ValueCellEditableContent
              {...valueProps}
              messageContent={messageContent}
              onValueChange={onValueChange}
              className={textPresetClass}
              symbolStart={modifiedSymbolStart}
              onFocus={focusAction}
            />
          ) : (
            <Grid
              item
              container
              wrap="nowrap"
              alignItems="center"
              className={classnames(
                textPresetClass,
                isHeader && "preset-tableHeader",
                valueContainerBackground && classes.valueContainerBackground,
              )}
            >
              <ValueCellReadOnlyContent
                showValueSign={showValueSign}
                symbolStart={modifiedSymbolStart}
                {...valueProps}
              />
            </Grid>
          ))}
        {children}
        {useSeparator && <span className="separator" />}
      </Grid>
    </Grid>
  );
});

ValueCell.propTypes = {
  ...InputCellProps,
  min: oneOfType([number, shape({ min: number, max: number })]),
  max: oneOfType([number, shape({ min: number, max: number })]),
  children: oneOfType([node, string]), //reset button
  narrow: bool,
  mini: bool,
  useSeparator: bool,
  // common
  highlightCellText: bool,
  showDecimals: bool,
  decimals: number,
  isHeader: bool,
  messageContent: string,
  messageTitle: string,
  justifyCellContent: oneOf(["center", "flex-start", "flex-end"]),
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
  valueContainerBackground: bool,
  onMouseEnter: func,
  onMouseLeave: func,
  showValueSign: bool,
  emptyInputs: bool,
  inputClassName: string,
  inputMinTooltipMessage: oneOfType([string, node]),
  inputMaxTooltipMessage: oneOfType([string, node]),
  placeholderMin: oneOfType([number, string]),
  placeholderMax: oneOfType([number, string]),
};
