import { memo } from "react";
import { bool, number, string, func, oneOfType, shape, node } from "prop-types";
import { ClearInput, ValueDivider } from "@components";
import classnames from "classnames";

export const ValueCellEditableContent = memo(({
  value,
  valueMax,
  onValueChange,
  displayMax,
  symbolStart,
  symbolEnd,
  showDecimals,
  emptyInputs,
  messageTitle,
  messageContent,
  isSmall,
  min = 0,
  max = 9999,
  warning,
  className,
  displayMinMaxSeparator = true,
  fullWidth,
  allowNegative,
  decimals,
  inputClassName,
  inputMinTooltipMessage,
  inputMaxTooltipMessage,
  placeholderMin,
  placeholderMax,
  onFocus,
}) => {
  const commonProps = {
    allowNegative,
    onFocus,
    fixedDecimalScale: showDecimals,
    decimals,
    prefix: emptyInputs ? undefined : symbolStart,
    suffix: emptyInputs ? undefined : symbolEnd,
    color: warning ? "warning" : "primary",
    fullWidth: fullWidth || displayMax,
    size: isSmall ? "small" : "large",
    clickDialogMessageContent: messageContent,
    clickDialogMessageTitle: messageTitle,
    tabIndex: messageContent ? -1 : undefined,
  }

  const handleValueChange = (isMax) => (value) => (
    onValueChange && onValueChange(value, isMax)
  )

  return (
    <>
      <ClearInput
        {...commonProps}
        value={emptyInputs ? undefined : value}
        onChange={handleValueChange(false)}
        alignContent={displayMax ? "right" : "center"}
        min={typeof min === "number" ? min : min?.min}
        max={typeof max === "number" ? max : max?.min}
        className={classnames(displayMax ? "input-min" : "input-avg", className, inputClassName)}
        tooltipMessage={emptyInputs ? undefined : inputMinTooltipMessage}
        placeholder={emptyInputs ? undefined : placeholderMin}
      />
      {
        displayMax &&
        <ValueDivider display={displayMinMaxSeparator} />
      }
      {
        displayMax &&
        <ClearInput
          {...commonProps}
          value={emptyInputs ? undefined : valueMax}
          onChange={handleValueChange(true)}
          alignContent="left"
          min={typeof min === "number" ? min : min?.max}
          max={typeof max === "number" ? max : max?.max}
          className={classnames("input-max", className, inputClassName)}
          tooltipMessage={emptyInputs ? undefined : inputMaxTooltipMessage}
          placeholder={emptyInputs ? undefined : placeholderMax}
        />
      }
    </>
  );
});

ValueCellEditableContent.propTypes = {
  value: oneOfType([number, string]),
  valueMax: oneOfType([number, string]),
  onValueChange: func,
  displayMax: bool,
  displayMinMaxSeparator: bool,
  symbolStart: string,
  symbolEnd: string,
  placeholder: oneOfType([string, number]),
  warning: bool,
  isSmall: bool,
  fullWidth: bool,
  emptyInputs: bool,
  min: oneOfType([number, shape({ min: number, max: number })]),
  max: oneOfType([number, shape({ min: number, max: number })]),
  // common
  showDecimals: bool,
  allowNegative: bool,
  className: string,
  messageTitle: string,
  messageContent: string,
  decimals: number,
  inputClassName: string,
  onFocus: func,
  inputMinTooltipMessage: oneOfType([string, node]),
  inputMaxTooltipMessage: oneOfType([string, node]),
  placeholderMin: oneOfType([number, string]),
  placeholderMax: oneOfType([number, string]),
};
