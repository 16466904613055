import { createContext } from "react";
import { node } from "prop-types";
import { useArchivedProjectStore, useStores, useWorkspaceWebsocket } from "@hooks";
import { EditorCommands } from "@client";
import { getProjectDetailsQuery } from "@query";
import { PROJECT_STATUS } from "@utils";


export const ArchivedProjectsSocketContext = createContext();

export const ArchivedProjectsSocketProvider = ({ children }) => {
  
  const { userStore } = useStores();
  const store = useArchivedProjectStore();

  const {
    socket,
    connectionOnline,
    sendAction,
    socketInitialized,
  } = useWorkspaceWebsocket();
  
  const setupArchivedProjectsEvents = (on) => {
    if(!on) {
      socket.current.removeAllListeners();
      return;
    }
    
    socket.current.on(EditorCommands.PROJECT_REMOVE, onProjectRemove);
    socket.current.on(EditorCommands.PROJECT_ARCHIVE, onProjectArchive);
    socket.current.on(EditorCommands.PROJECT_STATUS, onProjectStatusUpdate);
  }
  
  const joinArchivedProjects = () => {
    setupArchivedProjectsEvents(true);
    socket.current.emit(EditorCommands.JOIN_WORKSPACE_PROJECTS, {
      companyId: userStore.companyId,
      workspaceUuid: userStore.workspaceUuid,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
    });
  };
  const leaveArchivedProjects = () => {
    setupArchivedProjectsEvents(false);
    socket.current.emit(EditorCommands.LEAVE_WORKSPACE_PROJECTS);
  };
  
  const onProjectArchive = async ({ projectUuid }) => {
    const projectData = await getProjectDetailsQuery(projectUuid);
    store.addNewlyArchivedProject(projectData);
  }
  
  const onProjectRemove = ({ projectUuid }) => {
    store.removeArchivedProject(projectUuid);
  }
  
  const onProjectStatusUpdate = async ({ projectUuid, status }) => {
    if(status !== PROJECT_STATUS.ARCHIVE)
      store.removeArchivedProject(projectUuid);
  }
  
  // --------- USER ACTIONS ---------
  
  const requestProjectRemove = (projectUuid) => {
    sendAction(EditorCommands.PROJECT_REMOVE, { projectUuid });
  };
  
  const requestProjectStatusChange = (projectUuid, status, order, sourceStatus, sourceOrder) => {
    sendAction(EditorCommands.PROJECT_STATUS, { projectUuid, status, order, sourceStatus, sourceOrder });
  };
  
  const value = {
    connectionOnline,
    socketInitialized,
    joinArchivedProjects,
    leaveArchivedProjects,
    
    requestProjectRemove,
    requestProjectStatusChange,
  };

  return (
    <ArchivedProjectsSocketContext.Provider value={value}>
      {children}
    </ArchivedProjectsSocketContext.Provider>
  );
};

ArchivedProjectsSocketProvider.propTypes = {
  children: node.isRequired,
};
