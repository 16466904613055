import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { PriceCell } from "@components";

export const CostCell = observer(({
  element,
  isLastCell,
}) => {
  const structure = useStructureStore();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryCost);
  
  const { usesTwoValues, usesAnyValue, settings } = structure;
  const { currencyObj, roundPrice } = settings;
  const { displayPrice, displayPriceMax } = element;
  
  const showMaxValue = usesTwoValues && displayPrice !== displayPriceMax;
  
  return (
    <PriceCell
      displayContent
      widest={usesTwoValues}
      emptyInputs={!usesAnyValue}
      value={displayPrice}
      valueMax={displayPriceMax}
      displayMax={showMaxValue}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      allowNegative
      className={className}
      extendPadding={isLastCell}
      inputClassName="input-cost"
    />
  );
});

CostCell.propTypes = {
  element: object.isRequired,
  isLastCell: bool,
};
