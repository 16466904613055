import { useMemo, useState } from "react";
import { bool, object, string } from "prop-types";
import { useFeatureGuardian } from "@hooks";
import { LOCKED_FEATURES } from "@utils";
import { generateUserStoryQuery } from "@query";
import { MenuButton, TooltipIcon } from "@components";
import { useTranslation } from "react-i18next";
import { Rocket } from "@assets";
import { CircularProgress } from "@material-ui/core";
import classnames from "classnames";

export const UserStoryGenerator = ({
  element,
  lighterColor,
  menuAction
}) => {
  const { t } = useTranslation();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.AI_STORY
  );

  const [generatingStory, setGeneratingStory] = useState();

  const handleGenerateUserStory = async () => {
    const allowed = await checkAccess();
    if (!allowed || generatingStory || !element.canGenerateUserStory) return;

    setGeneratingStory(true);
    const story = await generateUserStoryQuery(element.name);
    if (story?.length) {
      element.setContent(story, true);
      setGeneratingStory(false);
    }
  };
  
  const text = useMemo(() => t(
    `views.editor.generate${element.canGenerateUserStory ? "" : "d"}_us`,
    { name: element.name }
  ), [element.canGenerateUserStory, element.name]);
  
  const icon = useMemo(() => (
    generatingStory ? <CircularProgress size={18} /> : <Rocket color={menuAction ? "primary" : undefined} />
  ), [generatingStory]);
  
  const commonProps = {
    icon,
    onClick: handleGenerateUserStory,
    disabled: !element.canGenerateUserStory
  }

  return (
    <>
      {
        menuAction
          ? <MenuButton {...commonProps} >
            {text}
          </MenuButton>
          :
          <TooltipIcon
            {...commonProps}
            color="primary"
            className={classnames(
              "button-ai-story",
              lighterColor,
              "noDrag hoverIcon",
              element.canGenerateUserStory && "opaque"
            )}
          >
            {text}
          </TooltipIcon>
      }
      <FeatureAlert />
    </>
  );
};

UserStoryGenerator.propTypes = {
  element: object.isRequired,
  lighterColor: string,
  menuAction: bool,
};
