import { bool, object } from "prop-types";
import { observer } from "mobx-react";
// import { useTranslation } from "react-i18next";
import {
  useStructureStore,
  useSummaryUnitCostInputTooltipAlert,
  useVisibilityModeCellClassName
} from "@hooks";
import { MAX_VALUES } from "@utils";
import { ValueCell } from "@components";

export const RateCell = observer(({
  element,
  allowEdition,
  // useClientActions,
  // isProposal,
  displayClientCellContent,
  displayCellContent,
  // editorOrClient,
  profitabilityMode,
}) => {
  // const { t } = useTranslation();
  const structure = useStructureStore();
  const tooltip = useSummaryUnitCostInputTooltipAlert(profitabilityMode, element.hasOnlyCost);
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryRate);
  
  const { usesAnyValue } = structure;
  const { currencyObj, modifier, timeModifier } = structure.settings;
  const { displayRate, hasResources, hasOnlyCost } = element;
  
  const handleRateChange = (rate) => {
    element.setRate(rate / modifier);//, useClientActions && isProposal);
  };

  return (
    <ValueCell
      widest
      showDecimals
      emptyInputs={hasOnlyCost || !usesAnyValue}
      value={displayRate}
      displayContent={profitabilityMode ? displayCellContent : displayClientCellContent}
      onValueChange={handleRateChange}
      editable={!hasResources && allowEdition}
      max={timeModifier === 1 ? MAX_VALUES.RATE_HOUR : MAX_VALUES.RATE_DAY}
      // placeholder={t("views.editor.summary_table.price_placeholder")}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      useSeparator={profitabilityMode}
      className={className}
      textPresetClass="preset-summaryText"
      inputClassName="input-rate"
      { ...tooltip }
    />
    //   {displayClientCellContent &&
    //     useClientActions &&
    //     isProposal &&
    //     hasClientRate && (
    //       <ResetValueButton action={element.resetClientRate}>
    //         {t("views.editor.alert")}
    //       </ResetValueButton>
    //     )}
    // </ValueCell>
  );
});

RateCell.propTypes = {
  element: object.isRequired,
  displayClientCellContent: bool,
  profitabilityMode: bool,
  allowEdition: bool,
  displayCellContent: bool,
};
