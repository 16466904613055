import { useEffect, useMemo, useState } from "react";
import { bool, func, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { StructureModel, unzipStructure, zipStructure } from "project-structure";
import { useActiveProjectStore, useStores } from "@hooks";
import {
  getProjectVersionStructureQuery,
  storeProjectBudgetTrackingQuery,
  fetchProjectBudgetTrackingQuery,
  fetchProjectIssueTypesQuery,
  fetchCompanyJiraProjects,
  storeIssuesBudgetTrackingQuery, forceProjectSaveQuery, checkBudgetTrackingQuery,
} from "@query";
import { Alert, Dialog } from "@components";
import { CreationProgress } from "@dialogs";
import { IssueMappingForm, JiraExportForm } from "@forms";
import {
  CircularProgress,
  Collapse,
  Grid,
} from "@material-ui/core";
import { JIRA_ACCESS_ERROR, TIME_TRACKING_ERROR } from "@utils";

export const JiraExportModal = observer(({
  onClose,
  uuid,
  projectJiraName,
  onOpenInfo
}) => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const activeProjects = useActiveProjectStore();

  const [ isLoading, setLoading ] = useState(true);
  const [ showProgress, setShowProgress ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const [ projectId, setProjectId ] = useState(null);
  const [ exportedProjectId, setExportedProjectId ] = useState(null);
  const [ savedIssueMappingData, setSavedIssueMappingData ] = useState(null);
  const [ savedJiraProject, setSavedJiraProject ] = useState();
  const [ savedJiraVersion, setSavedJiraVersion ] = useState();
  const [ jiraProjects, setJiraProjects ] = useState([]);
  const [ selectedJiraProject, setSelectedJiraProject ] = useState();
  const [ issueTypes, setIssueTypes ] = useState([]);
  const [ versions, setVersions ] = useState([]);
  const [ selectedVersionStructure, setStructure ] = useState();
  
  const [ timeTrackingAlertOpen, showTimeTrackingAlert ] = useState(false);
  const [ jiraAccessAlertOpen, showJiraAccessAlert ] = useState(false);

  const projectName = useMemo(() => (
    projectJiraName ||
    activeProjects.projects.find((p) => p.uuid === uuid)?.name ||
    ""
  ), []);

  useEffect(() => {
    (async () => {
      await forceProjectSaveQuery(uuid);
      const apropoProj = await getProjectVersionStructureQuery(uuid, true);
      setVersions(apropoProj.results.versions);
      setProjectId(apropoProj.results.id);
      
      const res = await fetchCompanyJiraProjects(userStore.data.companyId);
      setJiraProjects(res.data);
      
      if (projectJiraName) {
        const jiraProj = await fetchProjectBudgetTrackingQuery(
          apropoProj.results.id
        );
        if(jiraProj) {
          setSavedJiraVersion(jiraProj.version);
          setSavedJiraProject({
            id: jiraProj.jiraId.toString(),
            name: jiraProj.name,
          });
        }
      }

      setLoading(false);
    })();
  }, []);
  
  const authorizeJira = () => {
    checkBudgetTrackingQuery(
      projectId,
      zipStructure(uuid),
      userStore.data.companyId
    );
  };

  const saveProject = async ({ project: { name, id }, version }) => {
    setShowProgress(true);
    setErrorMessage(undefined);
    try {
      const res = await storeProjectBudgetTrackingQuery(projectId, version, id);
      setExportedProjectId(res.id);
      
      const issueTypesScheme = await fetchProjectIssueTypesQuery(res.id);
      
      await forceProjectSaveQuery(uuid);
      const apropoProj = await getProjectVersionStructureQuery(uuid, true);
      const versionObj = apropoProj.results.versions.find(
        ({ key }) => key === version
      );
      const structure = versionObj
        ? StructureModel.create(
            JSON.parse(unzipStructure(versionObj.structure))
          )
        : null;

      setIssueTypes(issueTypesScheme.data);
      setSelectedJiraProject({ id, name });
      setStructure(structure);
      setShowProgress(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.errors
      ) {
        if (err.response.status === 422) {
          err.response.data.errors?.key?.length === 1
            ? setErrorMessage(t(err.response.data.errors.key))
            : null;
          err.response.data.errors?.name?.length === 1
            ? setErrorMessage(t(err.response.data.errors.name))
            : null;
        }
      } else if (err.message) setErrorMessage(err.message);
    }
  };

  const saveProjectIssueMapping = async ({
    mergeWorkTypes,
    workTypes,
    mappingSections,
    ...maps
  }) => {
    if (!selectedVersionStructure)
      return;
    
    const mapping = {};
    for (let i = 0; i <= 3; i++) {
      if (maps[`level${i}Used`] && maps[`level${i}Map`])
        mapping[i] = maps[`level${i}Map`];
    }
    const data = {
      projectId: exportedProjectId,
      mergeWorkTypes,
      mapping,
      isTimeTracking: Boolean(workTypes.length),
      mappingSections,
      workTypes: workTypes.reduce((t, wT) => {
        const name = selectedVersionStructure?.workTypes.find(
          (w) => w.id === Number(wT)
        )?.name;
        if (!name) return t;
        return { ...t, [name]: Number(wT) };
      }, {}),
    };
    
    await commitIssueMapping(data);
  };
  
  const saveIssueMappingWithoutEstimates = async () => {
    if(!savedIssueMappingData) return;
    await commitIssueMapping({
      ...savedIssueMappingData,
      isTimeTracking: false,
      disableTimeSend: 1,
    });
  }
  
  const retrySaveProjectIssueMapping = async () => {
    if(!savedIssueMappingData) return;
    await commitIssueMapping(savedIssueMappingData);
  }
  
  const cancelSaveProjectIssueMapping = async () => {
    setSavedIssueMappingData(null);
    showTimeTrackingAlert(false);
    setErrorMessage(undefined);
    setShowProgress(false);
  }
  
  const commitIssueMapping = async (data) => {
    setSavedIssueMappingData(undefined);
    setErrorMessage(undefined);
    showTimeTrackingAlert(false);
    showJiraAccessAlert(false);
    
    if (!selectedVersionStructure)
      return;
    setShowProgress(true);
    try {
      await storeIssuesBudgetTrackingQuery(data);
      setShowProgress(false);
      activeProjects.updateLocalProject(String(uuid), {
        btExported: true,
      });
      onOpenInfo(exportedProjectId);
      onClose();
    } catch (err) {
      console.log(err?.response?.data, err?.response?.data?.message)
      if(err?.response?.data?.action === JIRA_ACCESS_ERROR) {
        setShowProgress(false);
        setErrorMessage(err.response.data.message);
        showJiraAccessAlert(true);
      } else if(err?.response?.data?.action === TIME_TRACKING_ERROR) {
        setShowProgress(false);
        setSavedIssueMappingData(data);
        setErrorMessage(err.response.data.message);
        showTimeTrackingAlert(true);
      } else if (err.response && err.response.data && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else if (err.message) setErrorMessage(err.message);
    }
  }

  const cancelSaving = () => {
    setErrorMessage("");
    setShowProgress(false);
  };

  return (
    <Dialog
      id="newProject"
      open
      onClose={onClose}
      actionsClass="btProjectForm"
      containerId="btProjectForm"
      containerClass="btProjectForm p-0"
      width={600}
      title={t("views.bt.select_project")}
    >
      {selectedJiraProject && (
        <h2 className="mb-4 px-6 pt-6 text-xl">{selectedJiraProject.name}</h2>
      )}
      {
        isLoading
          ? <Grid item container justifyContent="center" className="my-4">
              <CircularProgress />
            </Grid>
          : <>
            <Collapse
              in={!selectedJiraProject && projectName && !!versions.length}
              mountOnEnter
              unmountOnExit
            >
              <JiraExportForm
                onSubmit={saveProject}
                projects={jiraProjects}
                projectVersions={versions}
                defaultVersion={savedJiraVersion || versions[0].key}
                defaultProject={savedJiraProject}
              />
            </Collapse>
            <Collapse
              in={Boolean(selectedJiraProject && selectedVersionStructure)}
              mountOnEnter
              unmountOnExit
            >
              <IssueMappingForm
                issueTypes={issueTypes}
                onSubmit={saveProjectIssueMapping}
                selectedVersion={selectedVersionStructure}
                projectViewLevel={
                  selectedVersionStructure?.settings.viewLevel || 3
                }
              />
            </Collapse>
          </>
      }
      <CreationProgress
        open={showProgress}
        onCancel={cancelSaving}
        errorMessage={errorMessage}
      />
      <Alert
        isOpen={timeTrackingAlertOpen}
        onAccept={saveIssueMappingWithoutEstimates}
        onIntermediate={retrySaveProjectIssueMapping}
        onCancel={cancelSaveProjectIssueMapping}
        acceptText={t("views.bt.time_tracking_error.export_without")}
        intermediateText={t("views.bt.time_tracking_error.retry")}
        cancelText={t("views.bt.time_tracking_error.cancel")}
        title={errorMessage}
      />
      <Alert
        isOpen={jiraAccessAlertOpen}
        onAccept={authorizeJira}
        acceptText={t("views.bt.jira_access_error.accept")}
        title={errorMessage}
      />
      <Alert
        isOpen={showModal}
        title={t("views.new_project.finish")}
        acceptText={t("views.project.create")}
        onCancel={() => setShowModal(false)}
        onAccept={saveProject}
      />
    </Dialog>
  );
});

JiraExportModal.propTypes = {
  onClose: func.isRequired,
  uuid: string.isRequired,
  projectJiraName: string,
  onOpenInfo: bool,
};
