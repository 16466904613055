import { bool, object } from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

export const WorkTypeColumnExpand = ({
  section,
  blockExpansions,
}) => {
  const {t} = useTranslation();
  
  const { showCombinedHours } = section;
  
  const handleWorkTypeColumnExpand = () => {
    if(blockExpansions) return;
    section.setShowCombinedHours(!showCombinedHours);
  }
  
  return (
    <Tooltip
      title={t(`views.editor.estimate.${showCombinedHours ? "show" : "hide"}`)}
      disableHoverListener={blockExpansions}
      disableFocusListener={blockExpansions}
      disableTouchListener={blockExpansions}
    >
      <IconButton
        tabIndex={-1}
        size="small"
        color="primary"
        name="Expand/collapse work type columns"
        className={classnames("noDrag text-xl p-0", showCombinedHours ? "rotate-90" : "-rotate-90")}
        onClick={handleWorkTypeColumnExpand}
      >
        <ExpandMore />
      </IconButton>
    </Tooltip>
  )
}

WorkTypeColumnExpand.propTypes = {
  section: object.isRequired,
  blockExpansions: bool,
}