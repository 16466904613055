import { bool, number, string, object, func } from "prop-types";
import { usePresetFieldNames } from "@hooks";
import { HtmlParser } from "@utils";
import { isEmptyHtml, roundFloat } from "project-structure";
import classnames from "classnames";

/**
 * Simplified BreakdownRowGroup for PDF export
 * Specific for [ExportView](/#exportview) Component.
 */
export const ExportTask = ({
  translate,
  editorStore,
  settings,
  task,
  tableDepth,
  index,
  allowShowingPrices,
  noContent,
  isSubtotal,
  estimateViewLevel,
  estimateValueLevel,
  visibility,
  usesTwoValues,
  usesAnyValue,
  showCombinedHours,
}) => {
  const {
    showPrices,
    currencyObj,
    showBreakdownColumns,
    roundHours,
    roundType,
    showDecimals,
    roundLevel,
    usedRoundDecimals,
  } = settings;
  
  const { apply, hideMinValues } = visibility;

  const {
    name,
    content,
    isOpened,
    taskWorkTypes,
    children,
    displayPrice,
    displayPriceMax,
    showMaxPrice,
    isTurnedOff,
    hasCustomPrice,
    hasChildrenHours,
    parentHoldsPrice,
    combinedDisplayHours,
    combinedDisplayHoursMax,
    showMaxCombinedHours,
    areAllWorkTypesOff,
  } = task;
  
  const { getFieldName } = usePresetFieldNames(true, editorStore);

  const opened =
    tableDepth > 0 && estimateViewLevel > tableDepth && children?.length && isOpened;
  
  const showTaskDecimals = showDecimals || (
    typeof roundLevel === "number"
    && typeof task.lvl === "number"
    && task.lvl > roundLevel
  );
  
  const usedRounding = roundType && showDecimals ? 3 : roundHours;
  
  const visibleTaskWorkTypes = taskWorkTypes
    .filter(workType => !apply || !workType.isHidden);
  
  const noValues = (hasCustomPrice && !hasChildrenHours) || parentHoldsPrice;
  
  return (
    <>
      <tr
        className={classnames(`d${tableDepth}`, {
          hover: opened,
          off: isTurnedOff,
        })}
      >
        <td
          className={classnames(
            `d${tableDepth}`,
            isTurnedOff && "off",
            !isSubtotal && tableDepth > 0 && "name"
          )}
          style={{
            textAlign: isSubtotal ? "right" : "left",
            paddingLeft: 16 * (tableDepth || 1),
          }}
        >
          {isSubtotal ? (
            getFieldName("pt-subtotal")
          ) : (
            <>
              {name?.replace(/<(.|\n)*?>/g, "") || translate("common.unnamed")}
            </>
          )}
        </td>
        {showBreakdownColumns && !showCombinedHours &&
          visibleTaskWorkTypes
            ?.map(({ id }) => {
              const value = roundFloat(
                task.getDisplayHoursForWorkType(id),
                usedRounding, showTaskDecimals, usedRoundDecimals
              );
              const valueMax = roundFloat(
                task.getDisplayHoursMaxForWorkType(id),
                usedRounding, showTaskDecimals, usedRoundDecimals
              );
              const showValueMax = usesTwoValues && value !== valueMax;
              const workTypeIsOff = task.isWorkTypeOff(id);
              
              return (
                <td
                  key={id}
                  className={classnames(`d${tableDepth}`, (isTurnedOff || workTypeIsOff) && "off")}
                >
                  {!noValues && tableDepth <= estimateValueLevel && (
                    <>
                      { usesAnyValue && value }
                      { showValueMax && "  -  " }
                      { showValueMax && valueMax }
                    </>
                  )}
                </td>
              );
            })
        }
        {
          showBreakdownColumns && showCombinedHours &&
          <td
            className={ classnames(`d${ tableDepth }`, (isTurnedOff || areAllWorkTypesOff) && "off")}
          >
            { !noValues && tableDepth <= estimateValueLevel && (
              <>
                { usesAnyValue && combinedDisplayHours }
                { showMaxCombinedHours && "  -  " }
                { showMaxCombinedHours && combinedDisplayHoursMax }
              </>
            ) }
          </td>
        }
        { showBreakdownColumns && allowShowingPrices && showPrices && (
          <td className={ classnames(`d${ tableDepth }`/*, tableDepth > 0 && "cell-price"*/) }>
            { !parentHoldsPrice && (isSubtotal || tableDepth <= estimateValueLevel) && (
              <p
                className={ classnames(
                  `d${ tableDepth }`,
                  "price",
                  isTurnedOff && "off",
                ) }
              >
                {
                  usesAnyValue &&
                  <>
                    { currencyObj.symbolStart || "" }
                    { displayPrice }
                    { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
                  </>
                }
                { showMaxPrice && "  -  " }
                {
                  showMaxPrice &&
                  <>
                    { currencyObj.symbolStart || "" }
                    { displayPriceMax }
                    { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
                  </>
                }
              </p>
            )}
          </td>
        )}
      </tr>
      {!isEmptyHtml(content) && !noContent && (
        <tr className={`d${tableDepth}${opened ? " hover" : ""}`}>
          <td
            className={classnames(isTurnedOff ? "off" : false, "desc")}
            colSpan={
              1 +
              visibleTaskWorkTypes.length +
              (allowShowingPrices && showPrices ? 1 : 0)
            }
            style={{ paddingLeft: 16 * tableDepth + 20 }}
          >
            {HtmlParser(content)}
          </td>
        </tr>
      )}

      {opened &&
        children?.map((s, i) => (
          <ExportTask
            translate={translate}
            key={s.id || s.newId}
            index={`${index}.${i + 1}`}
            tableDepth={tableDepth + 1}
            task={s}
            editorStore={editorStore}
            settings={settings}
            visibility={visibility}
            allowShowingPrices={allowShowingPrices}
            estimateViewLevel={estimateViewLevel}
            estimateValueLevel={estimateValueLevel}
            usesTwoValues={usesTwoValues}
            usesAnyValue={usesAnyValue}
            showCombinedHours={showCombinedHours}
          />
        ))}
    </>
  );
};

ExportTask.propTypes = {
  translate: func.isRequired,
  editorStore: object.isRequired,
  task: object.isRequired,
  visibility: object.isRequired,
  settings: object.isRequired,
  index: string,
  tableDepth: number.isRequired,
  allowShowingPrices: bool,
  noContent: bool,
  isSubtotal: bool,
  usesTwoValues: bool,
  usesAnyValue: bool,
  showCombinedHours: bool,
  estimateViewLevel: number,
  estimateValueLevel: number,
};
