import def from "./themes/default";
//import light from "./themes/light";

// list of theme configs
export default { def /* ... */ };

export const APP_BAR_HEIGHTS = {
  SMALL: 56,
  LARGE: 64,
};
export const PDF_TOOLBAR_HEIGHT = 52;

export const MAX_PAGE_WIDTH = 1280;

export const EDITOR_TABLE_PADDING = {
  LARGE: 160,
  SMALL: 24
};

export const CONTENT_WIDTH = MAX_PAGE_WIDTH - 2*EDITOR_TABLE_PADDING.SMALL;
export const MAX_MEDIA_WIDTH = MAX_PAGE_WIDTH + 2*EDITOR_TABLE_PADDING.LARGE;