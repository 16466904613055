import { observer } from "mobx-react";
import { Grid, Switch } from "@material-ui/core";
import { InfoLabel, PresetPicker } from "@components";
import { ShareLinkModifiers } from "@utils";
import { saveShareSettingsQuery } from "@query";
import { useState } from "react";
import { useEditorWebsocket, useProjectEditorStore } from "@hooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ShareLinkSettings = observer(() => {
  
  const { t } = useTranslation();
  const { projectUuid } = useParams();
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();
  
  const { noTurningOff } = editorStore;
  
  const [isSendingSettings, setSendingSettings] = useState(false);
  
  const handleAllowTurnOff = async () => {
    editorStore.setShareLinkSettings(
      ShareLinkModifiers.NO_TURN_OFF,
      !noTurningOff
    );
    setSendingSettings(true);
    socket?.requestShareSettingsUpdate(editorStore.shareLinkSettings);
    await saveShareSettingsQuery(projectUuid, editorStore.shareLinkSettings);
    setSendingSettings(false);
  }
  
  return (
    <Grid
      item
      container
      direction="column"
      wrap="nowrap"
      alignItems="center"
    >
      <Grid item container justifyContent="space-between" className="mb-2">
        <InfoLabel label={t("views.editor.dialogs.share.settings.preset")} className="w-max m-0" />
        <PresetPicker
          commitPresetSelection
          noPresetCreate
        />
      </Grid>
      <Grid item container justifyContent="space-between">
        <InfoLabel label={t("views.editor.dialogs.share.settings.turn_off")} className="w-max m-0" />
        <Switch
          name="allowTurnOff"
          color="primary"
          checked={!noTurningOff}
          onChange={handleAllowTurnOff}
          disabled={isSendingSettings}
        />
      </Grid>
    </Grid>
  );
})