import {
  bool,
  string,
  number,
  node,
  object,
  func,
  arrayOf,
  oneOf,
  oneOfType, shape,
} from "prop-types";
import { observer } from "mobx-react";
import { SortableList } from "@components";
import { SummaryRowGroup } from "../SummaryRowGroup/SummaryRowGroup";
import { SummaryNestedRow } from "../SummaryNestedRow/SummaryNestedRow";

export const SummaryRowGroupList = observer(({
  allowEdition,
  isLastGroupRow,
  listElements,
  onReorder,
  children,
  parentIds,
  tableDepth = 0,
  ...elementProps
}) => {
  return (
    <>
      <SortableList
        list={listElements}
        path={parentIds.join("/")}
        group={parentIds.join("/")}
        onListReorder={onReorder}
        displayAsColumnFlexbox
        disabled={!allowEdition}
      >
        {
        listElements?.map((child, i) =>
          tableDepth
          ? (
            <SummaryNestedRow
              key={`element${i}${child.id}`}
              resource={child}
              allowEdition={allowEdition}
              isLastGroupRow={isLastGroupRow && i === listElements.length - 1}
              parentIds={parentIds}
              {...elementProps}
            />
          ) : (
            <SummaryRowGroup
              key={`element${i}${child.id}`}
              workType={child}
              allowEdition={allowEdition}
              parentIds={parentIds}
              {...elementProps}
            />
          )
        )}
      </SortableList>
      {children}
    </>
  );
});

SummaryRowGroupList.propTypes = {
  tableDepth: oneOf([0, 1, 2, 3]),
  listElements: arrayOf(object),
  onReorder: func,

  blockExpansions: bool,
  isLastGroupRow: bool,

  parentIds: arrayOf(oneOfType([number, string])),
  children: node,

  //common
  allowEdition: bool,
  isSellerOrClient: bool,
  isProposal: bool,
  useClientActions: bool,
  columns: arrayOf(shape({
    name: string.isRequired
  })).isRequired,
  maxTitleCellWidth: number
};
