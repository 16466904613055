import { useEffect } from "react";
import { object } from "prop-types";
import { useProjectEditorStore } from "@hooks";
import { observer } from "mobx-react";
import { PresetToolbar, ProposalStepper } from "../index";
import { StaticImg } from "@components";
import { AppBar, Toolbar } from "@material-ui/core";
import { ApropoLogo } from "@assets";
import { usePresetStyles } from "@styles";
import { PdfExport } from "@dialogs";
import { usePresetStore, useFonts } from "@hooks";
import { APP_BAR_HEIGHTS } from "@styles/themes";
import usePresetEditorStyle from "./PresetEditor.style";

export const ProposalCustomizationToolbar = observer(() => {
  const presetStore = usePresetStore();
  const editorStore = useProjectEditorStore();
  const { loadFonts, unloadFonts } = useFonts();

  const { proposalStep, companyLogo, currentVersion } = editorStore;
  const { selectedPresetData, selectedPresetFont } = presetStore;

  usePresetStyles(selectedPresetData, selectedPresetFont, !!proposalStep);
  usePresetEditorStyle(proposalStep === 1);
  
  useEffect(() => {
    if(selectedPresetFont && proposalStep === 2) {
      unloadFonts()
      loadFonts([selectedPresetFont])
      console.log("load fonts")
    }
  }, [currentVersion, proposalStep, selectedPresetFont]);
  
  return (
    <>
      <ProposalStepper />
      {proposalStep === 1 && (
        <>
          <PresetToolbar />
          <AppBar
            position="static"
            role="banner"
            className="preset-bar"
            style={{ zIndex: 900, boxShadow: "none" }}
          >
            <Toolbar style={{ justifyContent: "space-between" }}>
              <div
                className="preset-logo h-max w-max"
                style={{ maxHeight: APP_BAR_HEIGHTS.SMALL }}
              >
                {selectedPresetData && selectedPresetData.logo ? (
                  <StaticImg
                    src={selectedPresetData.logo}
                    // disableCache
                    className="h-full"
                    style={{ maxHeight: APP_BAR_HEIGHTS.SMALL }}
                  />
                ) : companyLogo ? (
                  <StaticImg
                    src={companyLogo}
                    // disableCache
                    className="h-full"
                    style={{ maxHeight: APP_BAR_HEIGHTS.SMALL }}
                  />
                ) : (
                  <ApropoLogo />
                )}
              </div>
              <PdfExport useSharedStyle disableActions />
            </Toolbar>
          </AppBar>
        </>
      )}
    </>
  );
});

ProposalCustomizationToolbar.propTypes = {
  tableRef: object,
};
