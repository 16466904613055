import { createContext } from "react";
import { node } from "prop-types";
import { useActiveProjectStore, useStores, useWorkspaceWebsocket } from "@hooks";
import { EditorCommands } from "@client";
import { getProjectDetailsQuery } from "@query";
import { PROJECT_COLUMNS, PROJECT_STATUS } from "@utils";


export const ActiveProjectsSocketContext = createContext();

export const ActiveProjectsSocketProvider = ({ children }) => {
  
  const { userStore } = useStores();
  const activeProjects = useActiveProjectStore();

  const {
    socket,
    connectionOnline,
    sendAction,
    requestProjectRename,
    socketInitialized,
  } = useWorkspaceWebsocket();
  
  const setupActiveProjectsEvents = (on) => {
    if(!on) {
      socket.current.removeAllListeners();
      return;
    }
    
    socket.current.on(EditorCommands.USER_ADD, onProjectUserAdd);
    socket.current.on(EditorCommands.USER_REMOVE, onProjectUserRemove);
    socket.current.on(EditorCommands.USER_PROMOTE, onProjectUserPromote);
    // socket.current.on(EditorCommands.LOGOUT, onUserLogout);
    socket.current.on(EditorCommands.PROJECT_CREATE, onProjectCreate);
    socket.current.on(EditorCommands.PROJECT_RENAME, onProjectRename);
    socket.current.on(EditorCommands.PROJECT_REMOVE, onProjectRemoveOrArchive);
    socket.current.on(EditorCommands.PROJECT_ARCHIVE, onProjectRemoveOrArchive);
    socket.current.on(EditorCommands.PROJECT_STATUS, onProjectStatusUpdate);
    socket.current.on(EditorCommands.WORKTYPE_ADD, onAddNewWorkType);
  }
  
  const joinActiveProjects = () => {
    setupActiveProjectsEvents(true);
    socket.current.emit(EditorCommands.JOIN_WORKSPACE_PROJECTS, {
      companyId: userStore.companyId,
      workspaceUuid: userStore.workspaceUuid,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
    });
  };
  const leaveActiveProjects = () => {
    setupActiveProjectsEvents(false);
    socket.current.emit(EditorCommands.LEAVE_WORKSPACE_PROJECTS);
  };
  
  const onAddNewWorkType = () => {
  
  };
  
  const onProjectRename = ({ projectUuid, newName }) => {
    activeProjects.editLocalProjectData(projectUuid, "name", newName);
  }
  
  const onProjectCreate = ({ projectData }) => {
    activeProjects.addProject(projectData);
  }
  
  const onProjectRemoveOrArchive = ({ projectUuid }) => {
    activeProjects.editLocalProjectData(projectUuid, "remove");
  }
  
  const onProjectStatusUpdate = async ({ projectUuid, ...data }) => {
    if(data.sourceStatus === PROJECT_STATUS.ARCHIVE  && Object.values(PROJECT_COLUMNS).includes(data.status)) {
      const projectData = await getProjectDetailsQuery(projectUuid);
      activeProjects.addProject(projectData);
    } else
      activeProjects.editLocalProjectData(projectUuid, "status", data);
  }
  
  const onProjectUserPromote = ({ projectUuid, userUuid }) => {
    const p = activeProjects.projects.find(p => p.uuid === projectUuid);
    if(!p) return;
    const newOwner = p.users.observer.find((u) => u.uuid === userUuid);
    const observer = p.users.observer.filter((u) => u.uuid !== userUuid);
    if (p.users.owner && p.users.owner.uuid !== p.users.author.uuid)
      observer.push({ ...p.users.owner });
    activeProjects.editLocalProjectData(projectUuid, "owner", newOwner);
    activeProjects.editLocalProjectData(projectUuid, "observers", observer);
  };
  
  const onProjectUserAdd = ({ projectUuid, ...userData }) => {
    const p = activeProjects.projects.find(p => p.uuid === projectUuid);
    if(!p) return;
    activeProjects.editLocalProjectData(projectUuid, "observers", [...p.users.observers, userData]);
  };
  
  const onProjectUserRemove = ({ projectUuid, userUuid }) => {
    const p = activeProjects.projects.find(p => p.uuid === projectUuid);
    if(!p) return;
      
    const observer = p.users.observer.filter((u) => u.uuid !== userUuid);
    if (p.users.owner && userUuid === p.users.owner.uuid) {
      const newOwner = p.users.author;
      observer.push({ ...p.users.owner });
      activeProjects.editLocalProjectData(projectUuid, "owner", newOwner);
    }
    
    activeProjects.editLocalProjectData(projectUuid, "observers", observer);
  };
  
  // --------- USER ACTIONS ---------
  
  const requestProjectUserAdd = (projectUuid, userData) => {
    sendAction(EditorCommands.USER_ADD, { projectUuid, ...userData });
  };
  
  const requestProjectUserPromote = (projectUuid, userUuid) => {
    sendAction(EditorCommands.USER_PROMOTE, { projectUuid, userUuid });
  };
  
  const requestProjectUserRemove = (projectUuid, userUuid) => {
    sendAction(EditorCommands.USER_REMOVE, { projectUuid, userUuid });
  };
  
  const requestProjectCreate = (projectData) => {
    sendAction(EditorCommands.PROJECT_CREATE, { projectData });
  };
  
  const requestProjectArchive = (projectUuid) => {
    sendAction(EditorCommands.PROJECT_ARCHIVE, { projectUuid });
  };
  
  const requestProjectRemove = (projectUuid) => {
    sendAction(EditorCommands.PROJECT_REMOVE, { projectUuid });
  };
  
  const requestProjectStatusChange = (projectUuid, status, order, sourceStatus, sourceOrder) => {
    sendAction(EditorCommands.PROJECT_STATUS, { projectUuid, status, order, sourceStatus, sourceOrder });
  };
  
  const value = {
    connectionOnline,
    socketInitialized,
    joinActiveProjects,
    leaveActiveProjects,
    
    requestProjectCreate,
    requestProjectRename,
    requestProjectUserAdd,
    requestProjectUserPromote,
    requestProjectUserRemove,
    requestProjectArchive,
    requestProjectRemove,
    requestProjectStatusChange,
  };

  return (
    <ActiveProjectsSocketContext.Provider value={value}>
      {children}
    </ActiveProjectsSocketContext.Provider>
  );
};

ActiveProjectsSocketProvider.propTypes = {
  children: node.isRequired,
};
