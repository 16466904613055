import { useRef } from "react";
import { observer } from "mobx-react";
import { ArchivedProjectStore, ArchivedProjectStoreProvider } from "@stores";
import { useStores, useWorkspaceWebsocket } from "@hooks";
import { ArchivedProjectsSocketProvider } from "@client";
import { PageCircularProgress } from "@components";
import { ArchivedProjectsView } from "./ArchivedProjectsView";

export const ArchivedProjects = observer(() => {
  const { userStore } = useStores();
  const socket = useWorkspaceWebsocket();
  
  const activeProjectStore = useRef(new ArchivedProjectStore());
  
  return !userStore.workspaceUuid || !socket?.socketInitialized
    ? (
      <PageCircularProgress />
    ) : (
      <ArchivedProjectStoreProvider value={activeProjectStore.current}>
        <ArchivedProjectsSocketProvider>
          <ArchivedProjectsView />
        </ArchivedProjectsSocketProvider>
      </ArchivedProjectStoreProvider>
    );
});
