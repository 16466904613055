import { observer } from "mobx-react";
import { StructureStoreProvider } from "@stores";
import {
  useProjectEditorStore,
  useEditorWebsocket,
  useCheckProjectAccess,
} from "@hooks";
import { PdfUploadProgress, EditorRoot, Alert } from "@components";
import { useNavigate } from "react-router-dom";
import { home } from "@paths";
import { useTranslation } from "react-i18next";

export const ProjectEditorView = observer(() => {
  const {t} = useTranslation();
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();
  const navigate = useNavigate();

  const {
    isArchived,
    isRemoved,
    isRestored,
    currentProjectStructure,
    proposalStep,
    projectUsers,
  } = editorStore;

  const {
    hasEditorPrivileges,
    hasSellerPrivileges
  } = useCheckProjectAccess(projectUsers);
  
  return (
    <StructureStoreProvider value={currentProjectStructure}>
      <EditorRoot
        hasEditorPrivileges={
          socket?.connectionOnline && !isArchived && hasEditorPrivileges
        }
        hasSellerPrivileges={hasSellerPrivileges}
        readOnly={!socket?.connectionOnline || isArchived || !!proposalStep}
        isProposal={!!proposalStep}
        useClientActions={
          !isArchived && hasEditorPrivileges && proposalStep !== 1
        }
        forceAllowVersionSelect={isArchived}
        showStepper={
          !isArchived &&
          !proposalStep &&
          hasSellerPrivileges &&
          hasEditorPrivileges &&
          socket?.connectionOnline
        }
        showPdfCover={proposalStep === 2}
        projectStructure={currentProjectStructure}
        showUserMovement={proposalStep === 0}
      />
      <PdfUploadProgress />
      {
        isRemoved &&
        <Alert
          isOpen
          title={t("alerts.editor.project_removed")}
          acceptText={t("alerts.editor.close_editor")}
          onAccept={() => navigate(home)}
        />
      }
      {
        isRestored &&
        <Alert
          isOpen
          title={t("alerts.editor.project_restored")}
          acceptText={t("common.close")}
          onAccept={() => editorStore.setRestored()}
        />
      }
    </StructureStoreProvider>
  );
});