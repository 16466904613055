import { bool, string, number, node } from "prop-types";
import { Alert } from "@components";
import { useTranslation } from "react-i18next";

export const JiraExportAndBTInfoDialog = ({
  openModal,
  onClose,
  title,
  width,
  actions,
  hideNotClose,
  children
}) => {
  const { t } = useTranslation();

  return (
    <Alert
      title={title}
      isOpen={openModal}
      onAccept={onClose}
      actions={actions}
      acceptText={t(`common.${hideNotClose ? "hide" : "close"}`)}
      cancelText={t("forms.contact.title")}
      width={width}
    >
      { children }
    </Alert>
  );
};

JiraExportAndBTInfoDialog.propTypes = {
  openModal: bool,
  onClose: bool,
  title: string,
  width: number,
  projectId: number,
  actions: string,
  hideNotClose: bool,
  children: node,
};
