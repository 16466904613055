import { useEffect, useMemo, useState } from "react";
import { useArchivedProjectsWebsocket, useStores } from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { CircularProgress, Container } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { ArchivedRow } from "./components/ArchivedRow";
import {
  PageTitle,
  ActionButton,
  Table,
  Checkbox,
  TablePagination,
  Alert,
} from "@components";
import { changeProjectStatusQuery, getArchivedProjectsQuery } from "@query";
import { isProjectOwner, PROJECT_STATUS } from "@utils";
import useStyle from "./ArchivedProjects.style";
import { useFeatureGuardian } from "@hooks";
import { LOCKED_FEATURES } from "@utils";
import { useArchivedProjectStore } from "@hooks/stores/useArchivedProjectStore";

export const ArchivedProjectsView = observer(() => {
  const classes = useStyle(isMobile);
  const { t } = useTranslation();
  const { userStore } = useStores();
  const socket = useArchivedProjectsWebsocket();
  const store = useArchivedProjectStore();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const [modalProjectUuid, setModalProjectUuid] = useState();
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);

  const { userId, isAdmin } = userStore;
  const { projects, page, rowsPerPage, total } = store;
  
  useEffect(() => {
    socket?.joinArchivedProjects();
    return () => {
      socket?.leaveArchivedProjects();
    }
  }, []);

  const checkAvailableProjects = async ({ allowed, info }) => {
    if (!allowed) return true;

    let available = info.value - info.used;
    const total = modalProjectUuid ? 1 : selectedProjects.length;

    if (available >= 0 && total > available) {
      await restoreProjects(available); // show alert & restore limited number of projects
      return true;
    }
  };

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ESTIMATION,
    checkAvailableProjects
  );

  const availableToSelect = useMemo(
    () =>
      projects.filter(({ users }) => isProjectOwner(userId, users, isAdmin))
        .length,
    [projects]
  );

  const hasCheckedAll = useMemo(
    () =>
      selectedProjects.length > 0 &&
      selectedProjects.length === availableToSelect,
    [selectedProjects, availableToSelect]
  );

  const hasChecked = useMemo(
    () => selectedProjects.length > 0 && !hasCheckedAll,
    [selectedProjects, hasCheckedAll]
  );

  const loadData = async () => {
    if (!isLoading) setIsLoading(true);
    const { results, total } = await getArchivedProjectsQuery(
      userStore.workspaceUuid,
      page + 1,
      rowsPerPage
    );
    store.setArchivedProjects(results, total);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [page, rowsPerPage]);

  const handleSelectAll = (event) => {
    let selectedProjects;

    if (event.target.checked) {
      selectedProjects = projects
        .filter(({ users }) => isProjectOwner(userId, users, isAdmin))
        .map(({ uuid }) => uuid);
    } else {
      selectedProjects = [];
    }

    setSelectedProjects(selectedProjects);
  };

  const handleSelectOne = (id) => {
    const selectedIndex = selectedProjects.indexOf(id);
    let newSelectedProjects = [];

    if (selectedIndex === -1) {
      newSelectedProjects = newSelectedProjects.concat(selectedProjects, id);
    } else if (selectedIndex === 0) {
      newSelectedProjects = newSelectedProjects.concat(
        selectedProjects.slice(1)
      );
    } else if (selectedIndex === selectedProjects.length - 1) {
      newSelectedProjects = newSelectedProjects.concat(
        selectedProjects.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedProjects = newSelectedProjects.concat(
        selectedProjects.slice(0, selectedIndex),
        selectedProjects.slice(selectedIndex + 1)
      );
    }

    setSelectedProjects(newSelectedProjects);
  };

  const restoreProject = async () => {
    setAlertLoading(true);
    const allowed = await checkAccess();
    if (!allowed) {
      setAlertLoading(false);
      closeRestoreModal();
      return;
    }

    restoreProjects();
  };

  const restoreProjects = async (available) => {
    if (modalProjectUuid) {
      await changeProjectStatusQuery(modalProjectUuid, PROJECT_STATUS.TODO, 0);
      socket?.requestProjectStatusChange(modalProjectUuid, PROJECT_STATUS.TODO, 0, PROJECT_STATUS.ARCHIVE);
      if (selectedProjects.includes(modalProjectUuid))
        handleSelectOne(modalProjectUuid);
    } else if (selectedProjects.length) {
      if (!available) available = selectedProjects.length;

      const toRestore = selectedProjects.slice(0, available).map(
        async (pr) => {
          socket?.requestProjectStatusChange(pr, PROJECT_STATUS.TODO, 0, PROJECT_STATUS.ARCHIVE);
          await changeProjectStatusQuery(pr, PROJECT_STATUS.TODO, 0);
        }
      );

      try {
        await Promise.all(toRestore);
        setSelectedProjects(selectedProjects.slice(available));
      } catch (e) {
        console.log(e); // @todo: proper error handling
      }
    }

    setAlertLoading(false);
    setRestoreDialog(false);
    setModalProjectUuid(undefined);
    await loadData();
  };

  const openRestoreModal = (id) => {
    setModalProjectUuid(id);
    setRestoreDialog(true);
  };

  const closeRestoreModal = () => {
    setRestoreDialog(false);
    setModalProjectUuid(undefined);
  };

  return (
    <>
      <PageTitle>
        <ActionButton
          icon={<Refresh style={{ transform: "scaleX(-1)" }} />}
          onClick={restoreProject}
          disabled={selectedProjects.length === 0}
        >
          {t("common.restore")}
        </ActionButton>
      </PageTitle>
      <Container>
        {
          isLoading
            ? <CircularProgress size={ 64 } className={classes.spinner} />
            : <>
              {
                !projects.length
                  ? <h3 className={classes.noArchived}>
                    {t("views.archived.not_found")}
                  </h3>
                  : <Table
                    id="archivedTable"
                    containerHeight
                    headers={[
                      {
                        label: (
                          <Checkbox
                            color="primary"
                            name="checkAll"
                            aria-label="select all rows"
                            checked={hasCheckedAll}
                            indeterminate={hasChecked}
                            onChange={handleSelectAll}
                          />
                        ),
                        width: 26,
                        sticky: 0,
                        justify: "center",
                      },
                      {
                        label: t("views.archived.name"),
                        width: 431,
                        //sticky: 26 + 24 + 1,
                        shadow: true,
                      },
                      // { label: t("views.archived.category"), width: 130 },
                      { label: t("views.archived.tags"), width: 320 },
                      { label: t("common.created"), width: 80 },
                      { label: t("common.deadline"), width: 80 },
                      { label: t("views.archived.members"), width: 130 },
                      { label: "", width: 34 },
                    ]}
                  >
                    {projects.map(
                      ({ uuid, name, category, tags, createdAt, dueOn, users }) => (
                        <ArchivedRow
                          key={uuid}
                          uuid={uuid}
                          name={name}
                          category={category}
                          tags={tags}
                          createdAt={createdAt}
                          dueOn={dueOn}
                          users={users}
                          selected={selectedProjects.indexOf(uuid) !== -1}
                          onRestore={openRestoreModal}
                          onSelect={handleSelectOne}
                          onRemove={loadData}
                        />
                      )
                    )}
                    <Alert
                      isOpen={restoreDialog}
                      onAccept={restoreProject}
                      onCancel={closeRestoreModal}
                      title={t("views.archived.restore_question")}
                      acceptText={
                        alertLoading ? (
                          <CircularProgress
                            aria-label="progress indicator"
                            size={24}
                            color="inherit"
                          />
                        ) : (
                          t("views.archived.restore_accept")
                        )
                      }
                    />
                  </Table>
              }
            </>
        }
        <TablePagination
          page={page}
          total={total}
          rowsPerPage={rowsPerPage}
          onPageChange={(p) => store.setPage(p)}
          onRowsPerPageChange={(r) => store.setRowsPerPage(r)}
        />
      </Container>
      <Alert
        isOpen={restoreDialog}
        onAccept={restoreProject}
        onCancel={closeRestoreModal}
        title={t("views.archived.restore_question")}
        acceptText={
          alertLoading ? (
            <CircularProgress
              aria-label="progress indicator"
              size={24}
              color="inherit"
            />
          ) : (
            t("views.archived.restore_accept")
          )
        }
      />
      <FeatureAlert />
    </>
  );
});
