import { bool, number, string } from "prop-types";
import { CELL_WIDTHS } from "@utils";
import { TitleCell, HeaderRow } from "@components";
import { Grid } from "@material-ui/core";
import { TableHeaderCell } from "./components/TableHeaderCell";
import { memo } from "react";

export const TableHeader = memo(({
  isProposal,
  tableIdentifier,
  useClientActions,
  timelineLength=0,
}) => (
  <HeaderRow
    id={tableIdentifier}
    useExpandCell
    altBackground
    className="preset-paperTimeline"
    expandCellPresetClass="preset-tableTimelineHeader"
  >
    <TitleCell
      pinnedLeft={1}
      maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
      minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
      allowOverflowDisplay
    />
    <Grid item container wrap="nowrap" className="month-container w-max">
      {
        [...new Array(timelineLength).keys()]
          .map((i) => <TableHeaderCell
            key={`month${i}`}
            monthIndex={i}
            isProposal={isProposal}
            useClientActions={useClientActions}
          />)
      }
    </Grid>
  </HeaderRow>
));

TableHeader.propTypes = {
  timelineLength: number.isRequired,
  tableIdentifier: string.isRequired,
  isProposal: bool,
  blockExpansions: bool,
  allowEdition: bool,
  useClientActions: bool,
};
