import { string, func, number, bool } from "prop-types";
import { TableSummary, TableTimeline, TableBreakdown } from "@components";
import { Tables } from "project-structure";
import { useStructureStore } from "@hooks";

export const EditorTablesFactory = ({
  index,
  tableName,
  onSectionReorder,
  breakdownTableIndex,
  ...tableProps
}) => {
  const structure = useStructureStore();
  
  const { visibility, usesAnyValue } = structure;
  
  const {
    apply,
    hideBreakdown,
    hideTimeline,
  } = visibility;
  
  switch (tableName) {
    case Tables.SUMMARY:
      return <TableSummary
        {...tableProps}
        breakdownTableIndex
        blockExpansions={tableProps.blockExpansions || tableProps.useProfitability}
        tableBreakdownNext={breakdownTableIndex === index+1}
        tableBreakdownPrev={breakdownTableIndex === index-1}
      />;
    case Tables.TIMELINE:
      if(tableProps.isProposal && apply && (hideTimeline || !usesAnyValue))
        return <></>
      return <TableTimeline
        tableBreakdownNext={breakdownTableIndex === index+1}
        tableBreakdownPrev={breakdownTableIndex === index-1}
        {...tableProps}
        tableIndex={index}
      />;
    case Tables.BREAKDOWN:
      if(!tableProps.hasEditorPrivileges && apply && hideBreakdown)
        return <></>
      return <TableBreakdown {...tableProps} onSectionReorder={onSectionReorder} />;
  }
};

EditorTablesFactory.propTypes = {
  index: number,
  tableName: string,
  onSectionReorder: func,
  breakdownTableIndex: number,
  presetEditionMode: bool,
};
