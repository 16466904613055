import { forwardRef, memo } from "react";
import { bool, string, node, func, object, oneOf, oneOfType } from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@assets";

export const TooltipIcon = memo(forwardRef(({
  icon,
  interactive,
  onClick,
  children,
  className,
  tooltipClassName,
  style,
  disabled,
  placement="bottom",
  color="inherit",
  ...buttonProps
}, ref ) => {
  return (
    <Tooltip
      arrow
      title={children}
      placement={placement}
      interactive={interactive}
      classes={{
        tooltip: tooltipClassName
      }}
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      disableTouchListener={disabled}
    >
      <IconButton
        color={color}
        className={className}
        style={style}
        size="small"
        tabIndex={-1}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        {...buttonProps}
      >
        {icon || <Info />}
      </IconButton>
    </Tooltip>
  );
}));

TooltipIcon.propTypes = {
  icon: node,
  children: oneOfType([string, node]).isRequired,
  color: oneOf(["default", "inherit", "primary", "secondary"]),
  placement: oneOf([
    "bottom-start",
    "bottom",
    "bottom-end",
    "left-start",
    "left",
    "left-end",
    "right-start",
    "right",
    "right-end",
  ]),
  interactive: bool,
  onClick: func,
  className: string,
  tooltipClassName: string,
  style: object,
  disabled: bool,
};
