import { bool, string, oneOf, func, node, number, oneOfType } from "prop-types";
import { observer } from "mobx-react";
import { useEditorCommentStore, usePresetFieldNames, useProjectEditorStore } from "@hooks";
import { VisibilityButton } from "@components";
import { Divider, Grid } from "@material-ui/core";
import { TotalValue, TotalValueEditable } from "./index";
import classnames from "classnames";
import useStyle from "../Totals.style";
import { ChatThread } from "@dialogs";

export const TotalContainer = observer(({
  totalName,
  isProposal,
  divider,
  editable,
  onValueChange,
  children,
  allowEdition,
  visibilityHidden,
  setVisibility,
  className,
  commentPath,
  showChatThread,
  allowCommenting,
  ...valueProps
}) => {
  
  const classes = useStyle();
  const commentStore = useEditorCommentStore();
  const { visibilityMode } = useProjectEditorStore();
  const { getFieldName } = usePresetFieldNames(isProposal);
  
  return (
    <Grid
      item
      container
      className={classnames("w-max px-5 vCon", allowEdition && "cursor-pointer", classes.container, className)}
      direction="column"
      alignItems="flex-end"
      justifyContent="center"
    >
      <Grid
        item container
        // alignItems="center"
        justifyContent="flex-end"
        className={classnames(
          "w-max",
          `preset-total${totalName}Title pt-total${totalName}`,
          classes.totalCost
        )}
      >
        {showChatThread && commentPath && commentStore?.commentsVisible && (
          <ChatThread
            rowThread
            path={`/totals/${commentPath}`}
            className="text-sm p-0-5 mx-2 -mt-0-5"
            allowCommenting={allowCommenting}
          />
        )}
        {
          visibilityMode &&
          <VisibilityButton hidden={visibilityHidden} onChange={setVisibility} size="tiny" />
        }
        {getFieldName(`pt-total${totalName}`)}
      </Grid>
      {
        onValueChange && editable
          ? <TotalValueEditable
            name={totalName}
            onValueChange={onValueChange}
            {...valueProps}
          >
            {children}
          </TotalValueEditable>
          : <TotalValue
            name={totalName}
            {...valueProps}
          />
      }
      {
        divider &&
        <Divider orientation="vertical" className={classes.divider} />
      }
    </Grid>
  )
});

TotalContainer.propTypes = {
  commentPath: oneOf(["cost", "hours", "time"]),
  min: oneOfType([number, string]).isRequired,
  max: oneOfType([number, string]),
  children: node, //additional items to show after value & symbols when editable
  onValueChange: func,
  symbolStart: oneOfType([string, node]),
  symbolEnd: oneOfType([string, node]),
  totalName: oneOf(["Hours", "Time", "Cost", "Revenue", "Profit", "Profitability"]),
  showMaxValue: bool,
  isProposal: bool,
  divider: bool,
  editable: bool,
  showDecimals: bool,
  textSmaller: bool,
  visibilityHidden: bool,
  setVisibility: func,
  className: string,
  showChatThread: bool,
  allowCommenting: bool,
}