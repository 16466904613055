import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles((theme) => ({
  priceCell: {
    width: (extend) => CELL_WIDTHS.PRICE.SMALL + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
    minWidth: (extend) => CELL_WIDTHS.PRICE.SMALL + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
  },
  cellMini: {
    width: (extend) => CELL_WIDTHS.VALUE.MINI + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
    minWidth: (extend) => CELL_WIDTHS.VALUE.MINI + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
  },
  priceCellBody: {
    color: theme.palette.text.primary,
  },
  priceContainer: {
    height: 24,
  },
  priceContainerBackground: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main + "0d",
  },
  valueCellWidth: {
    width: (extend) => CELL_WIDTHS.VALUE.SMALL + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
    minWidth: (extend) => CELL_WIDTHS.VALUE.SMALL + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
  },
  cellMedium: {
    width: (extend) => CELL_WIDTHS.PRICE.MEDIUM + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
    minWidth: (extend) => CELL_WIDTHS.PRICE.MEDIUM + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
  },
  cellLarge: {
    width: (extend) => CELL_WIDTHS.PRICE.LARGE + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
    minWidth: (extend) => CELL_WIDTHS.PRICE.LARGE + (extend ? CELL_WIDTHS.LAST_CELL_MODIFIER : 0),
  },
}));

export default useStyle;
