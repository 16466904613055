import { useTranslation } from "react-i18next";
import { usePresetStore, useProjectEditorStore } from "@hooks";

export const usePresetFieldNames = (
  usePresetTexts,
  editorStore = undefined
) => {
  const presetStore = usePresetStore();
  editorStore = editorStore || useProjectEditorStore();
  const { t } = useTranslation();

  const getFieldName = (field, startingLevel = undefined) => {
    const settings = editorStore?.currentProjectStructure?.settings;

    let name = usePresetTexts
      ? presetStore?.selectedPresetData.texts?.[field]
      : undefined;
    if (name) return name;
    // if (typeof name !== "undefined") return name;

    if (startingLevel === undefined)
      startingLevel = settings?.sectionFormat === 1 ? 0 : 1;

    switch (field) {
      case "pt-summary":
        return t("proposal_parts.editor_sections.summary");
      case "pt-timeline":
        return t("proposal_parts.editor_sections.timeline");
      // case "pt-timelineBeta":
      //   return t("proposal_parts.editor_sections.timeline_beta");
      case "pt-breakdown":
        return t("proposal_parts.editor_sections.breakdown");
      case "pt-timelineSections":
        return t("proposal_parts.timeline_dropdowns.sections");
      case "pt-timelineTeam":
        return t("proposal_parts.timeline_dropdowns.team");
      case "pt-timelineBilling":
        return t("proposal_parts.timeline_dropdowns.billing");
      case "pt-totalCost":
        return t("views.editor.summary_table.totals.cost");
      case "pt-totalRevenue":
        return t("views.editor.summary_table.totals.revenue");
      case "pt-totalProfit":
        return t("views.editor.summary_table.totals.profit");
      case "pt-totalProfitability":
        return t("views.editor.summary_table.totals.profitability");
      case "pt-totalTime":
        return t("views.editor.summary_table.totals.time");
      case "pt-totalTimeMonths":
        return ` ${t("times.month")}`;
      case "pt-totalTimeWeeks":
        return ` ${ t("times.week")}`;
      case "pt-totalHours":
        return t(
          `views.editor.summary_table.totals.${
            settings?.timeModifier === 1 ? "hours" : "days"
          }`
        );
      case "pt-work":
        return t("common.work_type");
      case "pt-rate":
        return t(
          `views.editor.summary_table.rate_${
            settings?.timeModifier === 1 ? "hours" : "days"
          }`
        );
      case "pt-cost":
        return t("views.editor.summary_table.cost");
        // return t("views.editor.summary_table.cost_currency", {
        //   currency: settings?.currencyObj.symbolStart || "$",
        // });
      case "pt-revenue":
        return t("views.editor.summary_table.revenue");
      case "pt-price":
        return t("views.editor.price");
      case "pt-combinedEstimate":
        return t(`views.editor.estimate.${settings?.timeModifier === 1 ? "hours" : "days"}`);
      case "pt-role":
        return t("views.editor.timeline_table.role");
      case "pt-min":
        return t("common.min");
      case "pt-max":
        return t("common.max");
      case "pt-billing":
        return t("views.editor.timeline_table.billing");
      case "pt-total":
        return t("common.total");
      case "pt-subtotal":
        return t("common.subtotal");
      case "pt-timeMta":
        return t(`views.editor.summary_table.time_${settings?.useWeekTime ? "weeks" : "months"}`);
      case "pt-profit":
        return t("views.editor.summary_table.profit");
      case "pt-profitability":
        return t("views.editor.summary_table.profitability");
      case "pt-hourlyCost":
        return t(
          `views.editor.summary_table.cost_${
            settings?.timeModifier === 1 ? "hours" : "days"
          }`
        );
      case "pt-teamMembers":
        return t("views.editor.summary_table.team_members");
      case "pt-time":
        return t(`times.${settings?.timeModifier === 1 ? "hour" : "day"}`);
      case "pt-module":
        return t(
          `proposal_parts.${
            ["section", "module", "feature", "task"][startingLevel]
          }_title.${settings?.viewLevel || 3}`
        );
      case "pt-fixed":
        return t("views.editor.summary_table.fixed_costs");
    }
  };

  return { getFieldName };
};
