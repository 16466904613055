import { bool, number } from "prop-types";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { VisibilityButton } from "@components";
import { Collapse, Grid } from "@material-ui/core";
import { BillingRow, TimelineSectionTitleRow } from "../components";

export const TimelineBilling = observer(({
  isProposal,
  useClientActions,
  timelineLength,
  blockExpansions,
}) => {
  
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  
  const { settings, visibility, workTypesHaveResources } = structure;
  const { displayTimelineBilling } = settings;
  const { hideTimelineBilling } = visibility;
  
  const className = useVisibilityModeCellClassName(hideTimelineBilling);
  
  const handleTimelineBillingVisibility = () => {
    if(blockExpansions) return;
    settings.setDisplayTimelineBilling(!displayTimelineBilling);
  }
  
  return (
    <Grid item container className="vCon">
      <TimelineSectionTitleRow
        isProposal={isProposal}
        code="billing"
        expandable
        expanded={displayTimelineBilling}
        onExpand={handleTimelineBillingVisibility}
        className={className}
        timelineLength={timelineLength}
        action={visibilityMode &&
          <VisibilityButton hidden={hideTimelineBilling} onChange={visibility.setHideTimelineBilling} />
        }
      />
      <Collapse
        in={displayTimelineBilling}
        timeout="auto"
        mountOnEnter
        unmountOnExit
        className={className}
      >
        <BillingRow
          useExpandCell={!isProposal || workTypesHaveResources}
          isProposal={isProposal}
          blockExpansions={blockExpansions}
          timelineLength={timelineLength}
        />
      </Collapse>
    </Grid>
  );
});

TimelineBilling.propTypes = {
  timelineLength: number.isRequired,
  useClientActions: bool,
  blockExpansions: bool,
  isProposal: bool,
};