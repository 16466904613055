import { bool, func, object, string } from "prop-types";
import { Columns } from "project-structure";

export const ExportTotals = ({
  name,
  currentProjectStructure,
  translate,
  usesAnyValue,
}) => {
  
  const {
    settings,
    visibility,
    displayTotalPrice,
    displayTotalPriceMax,
    displayTotalHours,
    displayTotalHoursMax,
    displayTotalTime,
    displayTotalTimeMax,
    showMaxTotalHours,
    showMaxTotalTime,
    showMaxTotalPrice,
  } = currentProjectStructure;
  
  const {
    currencyObj,
    timeModifier,
  } = settings;
  
  
  const {
    apply,
    hideTotalTime,
    hideTotalCost,
    hideTotalHours
  } = visibility;
  
  switch(name) {
    case Columns.COST:
      if(apply && hideTotalCost) return <></>
      return (
        <strong className="total-cost">
          {
            usesAnyValue &&
            <>
              { currencyObj.symbolStart || "" }
              { displayTotalPrice }
              { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
            </>
          }
          { showMaxTotalPrice && "  -  "}
          { showMaxTotalPrice && (
            <>
              { currencyObj.symbolStart || "" }
              { displayTotalPriceMax }
              { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
            </>
          )}
        </strong>
      )
    case Columns.HOURS:
      if(apply && hideTotalHours) return <></>
      return (
        <strong className="total-hours">
          {
            usesAnyValue &&
            <>
              { displayTotalHours }
              {" "}
              {translate(`times.${timeModifier === 1 ? "h" : "day"}`)}
            </>
          }
          { showMaxTotalHours && "  -  " }
          { showMaxTotalHours && (
            <>
              { displayTotalHoursMax }
              {" "}
              {translate(`times.${timeModifier === 1 ? "h" : "day"}`)}
            </>
          )}
        </strong>
      )
    case Columns.TIME:
      if(apply && hideTotalTime) return <></>
      return (
        <strong className="total-time">
          {
            usesAnyValue &&
            <>
              { displayTotalTime }
              { " " }
              { translate("times.month") }
            </>
          }
          { showMaxTotalTime &&  "  -  "}
          { showMaxTotalTime && (
            <>
              { displayTotalTimeMax }
              { " " }
              { translate("times.month") }
            </>
          )}
        </strong>
      )
    default:
      return <></>
  }
}

ExportTotals.propTypes = {
  translate: func,
  name: string,
  currentProjectStructure: object,
  usesAnyValue: bool,
}