import { useEffect, useMemo } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ResizeObserver from "react-resize-observer";
import { isMobile } from "react-device-detect";
import {
  Tables,
  hashCode,
  hasOlderOriginBuild,
  RISK_BUFFER_LOCK_BUILD,
  DEFAULT_PROFITABILITY_COLUMNS,
} from "project-structure";
import {
  useStructureStore,
  usePresetFieldNames,
  useTableOverflowChecker,
  useTableExpandHandler,
  useProjectEditorStore, getUserVisibleWorkTypes, useVisibilityModeCellClassName,
} from "@hooks";
import { Overheads, SummarySettings } from "@dialogs";
import {
  AddButtonFull,
  TableTitle,
  SummaryRow,
  TitleCell,
  Totals,
  ProfitabilityTotals,
  MinMaxSwitch,
  Checkbox,
  ScrollButtonContainer,
  VisibilityButton,
} from "@components";
import { TableContainer, Grid, Collapse } from "@material-ui/core";
import { SummaryRowGroupList } from "./components/SummaryRowGroupList/SummaryRowGroupList";
import { FixedPriceRow } from "../rows/FixedPriceRow/FixedPriceRow";
import { TableHeader } from "./components/TableHeader/TableHeader";
import { CurrencyExchangeSettings } from "./components/CurrencyExchangeSettings";
import useStyle from "../commonStyles.style";
import classnames from "classnames";

export const TableSummary = observer(({
  allowEdition,
  isSellerOrClient,
  showChatThread,
  isProposal,
  readOnly,
  blockExpansions,
  tableBreakdownNext,
  tableBreakdownPrev,
  useProfitability,
  presetEditionMode,
  showPdfCover,
  ...otherProps
}) => {

  // values & systems from dataStore
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { t } = useTranslation();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const classes = useStyle();

  const { settings, workTypes, hasFixedBreakdownPrice, workTypesHaveResources, visibility } = structure;
  const { showSummary, useMinMax, currencyObj, visibleSummaryColumns, hasTeamMembersCol, companyCurrency, currency } = settings;
  const { apply, hideSummary, hideMinValues, hideMaxValues, useAvgValues } = visibility;
  
  const className = useVisibilityModeCellClassName(hideSummary);
  
  const visibleWorkTypes = getUserVisibleWorkTypes(workTypes, isProposal);
  
  const showOverheads = useMemo(() => Boolean(
    !hasOlderOriginBuild(RISK_BUFFER_LOCK_BUILD, structure.sOriginBuild)
  ), [structure.sOriginBuild]);
  
  const useExpandCell = useMemo(
    () => hasTeamMembersCol && ((!readOnly && !useProfitability) || workTypesHaveResources),
    [hasTeamMembersCol, readOnly, workTypesHaveResources]
  );
  
  const columns = useMemo(() => (
    useProfitability
      ? DEFAULT_PROFITABILITY_COLUMNS
      : visibleSummaryColumns
  ), [useProfitability, visibleSummaryColumns]);
  
  const tableContainerIdentifier = "summaryContainer";
  const tableIdentifier = "summaryHeader";
  
  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);
  
  useEffect(() => {
    onResize();
  }, [useMinMax, useProfitability, companyCurrency, currency]);

  const addNewWorkType = () => {
    structure.addWorkType(true, { id: -new Date().getTime() }, 0);
  };
  
  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    !blockExpansions,
    showSummary,
    settings.setShowSummary
  );

  const handleWorkTypeReorder = (list) => structure.reorderWorkTypes(list);
  
  const handleUseAvgValuesChange = () => {
    structure.historyManager.startGroup();
    structure.visibility.setUseAvgValues(!useAvgValues)
    structure.generateTimeline();
    structure.historyManager.stopGroup();
  };
  
  const childProps = {
    isProposal,
    isSellerOrClient,
    columns,
    useExpandCell,
    allowEdition,
    useProfitability,
    showChatThread,
    ...otherProps
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className={classnames(
        "preset-paper",
        classes.whiteContainer,
        (tableBreakdownNext || useProfitability) && classes.whiteContainerLast,
        tableBreakdownPrev && classes.whiteContainerFirst
      )}
    >
      <Grid item container justifyContent="flex-end" className={classnames("vCon", showPdfCover && "pdf-mode", classes.root)}>
        {
          (!isProposal || !apply || !hideSummary) &&
          <>
            <TableTitle
              disabled={visibilityMode && hideSummary}
              expandable={!blockExpansions}
              presetClass="summary"
              buttonPresetClass="preset-tableSummaryExpandButton"
              id={hashCode(Tables.SUMMARY)}
              title={getFieldName("pt-summary")}
              showContent={showSummary}
              onContentAction={handleOpen}
              presetEditionMode={presetEditionMode}
              action={visibilityMode &&
                <VisibilityButton hidden={hideSummary} onChange={visibility.setHideSummary} />
              }
            >
              {
                allowEdition && !useProfitability &&
                <Grid
                  item container
                  alignItems="center"
                  className="w-max my-1"
                >
                  { showOverheads && <Overheads/> }
                  <SummarySettings isSellerOrClient={isSellerOrClient} />
                  <MinMaxSwitch />
                </Grid>
              }
              {
                visibilityMode && useMinMax && !useProfitability &&
                <Grid
                  item container
                  className="w-max"
                  alignItems="center"
                >
                  <Checkbox
                    name="min"
                    checked={!hideMinValues}
                    disabled={useAvgValues}
                    label={t("common.min")}
                    onChange={() => visibility.setHideMinValues(!hideMinValues)}
                  />
                  <Checkbox
                    name="avg"
                    checked={Boolean(useAvgValues)}
                    label={t("common.avg")}
                    onChange={handleUseAvgValuesChange}
                    className="ml-2"
                  />
                  <Checkbox
                    name="max"
                    checked={!hideMaxValues}
                    disabled={useAvgValues}
                    label={t("common.max")}
                    onChange={() => visibility.setHideMaxValues(!hideMaxValues)}
                    className="ml-2"
                  />
                  {/*<MinMaxSwitch />*/}
                </Grid>
              }
              {
                allowEdition && useProfitability && companyCurrency !== currency &&
                <CurrencyExchangeSettings />
              }
            </TableTitle>
            <Collapse
              in={showSummary || useProfitability}
              timeout="auto"
              mountOnEnter
              unmountOnExit
              className={classnames(showPdfCover && "pdf-mode", classes.collapse, className)}
              data-id={hashCode("cont")}
              data-t="summary"
              data-p={hashCode(Tables.SUMMARY)}
              { ...collapseProps }
            >
              <Grid
                container
                justifyContent="center"
                wrap="nowrap"
                direction="column"
              >
                {
                  isTableContentOverflowing && !isMobile &&
                  <ScrollButtonContainer {...scrollButtonProps} />
                }
                <TableContainer
                  ref={ref}
                  id={tableContainerIdentifier}
                  onScroll={onScroll}
                  className={classnames(
                    "pb-3",
                    classes.tableContainer,
                    isTableContentOverflowing && classes.overflows
                  )}
                >
                  <Grid
                    container
                    role="rowgroup"
                    direction="column"
                  >
                    <ResizeObserver onResize={onResize} />
                    <TableHeader
                      tableIdentifier={tableIdentifier}
                      showPrices={isSellerOrClient}
                      currencyCode={currencyObj.code}
                      {...childProps}
                    />
                    <SummaryRowGroupList
                      parentIds={["summary"]}
                      listElements={visibleWorkTypes}
                      onReorder={handleWorkTypeReorder}
                      isLastGroupRow
                      blockExpansions={blockExpansions}
                      {...childProps}
                    />
                    {hasFixedBreakdownPrice && (
                      <FixedPriceRow {...childProps} />
                    )}
                    {allowEdition && !useProfitability && workTypes.length === visibleWorkTypes.length && (
                      <SummaryRow>
                        <TitleCell
                          pinnedLeft={0}
                          minWidth={0}
                          stretchToChildrenWidth
                        >
                          <AddButtonFull
                            id="newWorkTypeButton"
                            onClick={addNewWorkType}
                            name="Add new work type to summary"
                          >
                            {t("views.editor.summary_table.work_add")}
                          </AddButtonFull>
                        </TitleCell>
                      </SummaryRow>
                    )}
                  </Grid>
                </TableContainer>
              </Grid>
            </Collapse>
          </>
        }
        {
          useProfitability
            ? <ProfitabilityTotals allowEdition={allowEdition} />
            : <Totals {...childProps} />
        }
      </Grid>
    </Grid>
  );
});

TableSummary.propTypes = {
  hasSellerPrivileges: bool,
  isProposal: bool,
  useClientActions: bool,
  useProfitability: bool,
  tableBreakdownNext: bool,
  tableBreakdownPrev: bool,
  presetEditionMode: bool,
  noTurningOff: bool,
  showPdfCover: bool,
  showChatThread: bool,
  allowCommenting: bool,
  blockExpansions: bool,
  useVisibilityCell: bool,
};
